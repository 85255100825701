import React, { useState } from 'react';
import { useDispatch, useStore } from 'store/StateProvider';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import { setLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';
import styles from './Snackbar.module.css';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import { setWelcomeSnackBar } from 'common/reducer-actions/commonActions';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';

const WelcomeBonus = () => {
  const isLoggedIn = isUserLoggedIn();
  const intl = useIntl();
  const [fromSnack] = useState(true);
  const dispatch = useDispatch();
  const [store] = useStore();
  const isWelcomeSnackBar = store.common?.isWelcomeSnackBar;

  const closeWelcomeSnackbar = (isSnackbar) => {
    setLocalStorage('welcomeSnackbar', isSnackbar);
    setWelcomeSnackBar(dispatch, isSnackbar);
  };

  return !isLoggedIn && isWelcomeSnackBar ? (
    <>
      <div className={`${styles.header} ${styles.joinSnack} header`} id="snackBar">
        <div className={`${styles.leftHeader}`}>
          <div className={styles.leftHeaderDiv}>
            <img
              src={MobileImages.home.welcomeBonusIcon}
              className={styles.welcomeBonusIcon}
              alt="register"
              width={23}
              height={18}
            />{' '}
            <span>{intl.formatMessage({ id: 'topbar.welcomeBonusTile' })}</span>
          </div>
        </div>
        <div className={styles.rightHeader}>
          <div className={styles.joinBtn}>
            <Link to={ROUTES.ACCOUNT.REGISTER} state={{ fromSnack }}>
              <div className={styles.joinSpan}>
                {intl.formatMessage({ id: 'topbar.joinNowBtn' })}
              </div>
            </Link>
          </div>
          <div onClick={() => closeWelcomeSnackbar(false)}>
            <ThemeIcon
              IconComponent={IconClose2}
              activeColor="black"
              iconActive={true}
              width={10}
              height={10}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default WelcomeBonus;
