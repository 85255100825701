import React, { useCallback, useEffect } from 'react';
import CommonFrame from '../../../common/commonFrame/CommonFrame';
import { useIntl } from 'react-intl';
import styles from './USDTOfflinePay.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconCopy from '../../../common/icon/IconCopy';
import { enqueueSnackbarMsg } from '../../../../../common/helpers/helper';
import { useSnackbar } from 'notistack';
import ThemeIcon from '../../../common/icon/ThemeIcon';
import { MobileImages } from '../../../../../common/constants/image-resource';
import NumFormat from '../../../../shared/numformat/NumFormat';
import SubmitButton from '../../../../shared/buttons/SubmitButton';
import { ROUTES } from '../../../../../common/constants/routing';
import { useLocation, useNavigate } from 'react-router-dom';

const USDTOfflinePay = (props) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state || {};
  const { depositAmt, rates, appID } = state;

  const copyUSDTAddress = useCallback(() => {
    return enqueueSnackbarMsg(intl.formatMessage({ id: 'app.copy_usdt_link' }));
  }, [enqueueSnackbarMsg]);

  const contactCustomerService = () => {
    navigate(ROUTES.LIVE_SUPPORT.ROOT);
  };

  //if no have these value, redirect home
  useEffect(() => {
    if (navigate) {
      if (
        typeof depositAmt === 'undefined' ||
        typeof rates === 'undefined' ||
        typeof appID === 'undefined'
      ) {
        navigate(ROUTES.HOME);
      }
    }
  }, [navigate]);
  return (
    <>
      <div className={styles.title}>{intl.formatMessage({ id: 'deposit.usdt_deposit_label' })}</div>
      <CommonFrame
        titleValue={intl.formatMessage({ id: 'deposit.copy_usdt_wallet_addr' })}
        startItem={1}
        containerClass={styles.noBorder}
      >
        <div className={styles.link}>
          <img src={MobileImages.usdt.usdtBg} alt="usdt-icon" width={20} height={20} />
          <p>{appID}</p>
          <CopyToClipboard className={styles.copyLink} text={appID} onCopy={copyUSDTAddress}>
            <div>
              <ThemeIcon IconComponent={IconCopy} width={20} height={20} />
            </div>
          </CopyToClipboard>
        </div>
        <div className={styles.details}>
          <div className={styles.content}>
            <span>{intl.formatMessage({ id: 'deposit.deposit_amt_label' })}</span>
            <span>
              <NumFormat
                value={depositAmt}
                prefix={window.CURRENCY_CODE}
                decimalScale={2}
                thousandSeparator=","
              />
            </span>
          </div>
          <div className={styles.content}>
            <span>{intl.formatMessage({ id: 'deposit.deposit_rates' })}</span>
            <div>
              <NumFormat value={1 / rates} decimalScale={4} />
            </div>
          </div>
          <div className={styles.content}>
            <span>{intl.formatMessage({ id: 'deposit.deposit_usdt_amt_label' })}</span>
            <span>
              <NumFormat
                value={depositAmt / rates}
                decimalScale={2}
                suffix={' USDT'}
                thousandSeparator=","
              />
            </span>
          </div>
        </div>
      </CommonFrame>
      <CommonFrame
        titleValue={intl.formatMessage({ id: 'deposit.deposit_inform_cs' })}
        startItem={2}
        containerClass={styles.noBorder}
      >
        <SubmitButton
          value={intl.formatMessage({ id: 'app.contact_cs' })}
          type="button"
          externalClassName={styles.contactCS}
          handleClick={contactCustomerService}
        ></SubmitButton>
      </CommonFrame>
    </>
  );
};
export default USDTOfflinePay;
