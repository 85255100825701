import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import { ROUTES } from 'common/constants/routing';
import IconLogout from 'component/mobile/common/icon/IconLogout';
import IconProfile from 'component/mobile/common/icon/IconProfile';
import IconDeposit from 'component/mobile/common/icon/IconDeposit';
// import IconMessage from 'component/mobile/common/icon/IconMessage';
import IconWithdraw from 'component/mobile/common/icon/IconWithdraw';
import IconRakeBack from 'component/mobile/common/icon/IconRakeBack';
import IconReferral from 'component/mobile/common/icon/IconReferral';
import IconVoucher from 'component/mobile/common/icon/IconVoucher';
import IconTransactions from 'component/mobile/common/icon/IconTransactions';
import IconVault from 'component/mobile/common/icon/IconVault';
import VipLevelFrame from 'component/mobile/common/vipLevelFrame/VipLevelFrame';
import ProfileMenuItem from './ProfileMenuItem';
import styles from './Profile.module.css';
import { MobileImages } from 'common/constants/image-resource';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';

const Profile = ({
  nickName,
  loginName,
  vipLevelName,
  vipLevel,
  vipLevelProgress,
  handleCloseGame,
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const iconProfile = useMemo(
    () => <ThemeIcon IconComponent={IconProfile} level="secondary" />,
    [],
  );
  const iconDeposit = useMemo(
    () => <ThemeIcon IconComponent={IconDeposit} level="secondary" />,
    [],
  );
  const iconWithdraw = useMemo(
    () => <ThemeIcon IconComponent={IconWithdraw} level="secondary" />,
    [],
  );
  const iconTransactions = useMemo(
    () => <ThemeIcon IconComponent={IconTransactions} level="secondary" />,
    [],
  );
  const iconRakeBack = useMemo(
    () => <ThemeIcon IconComponent={IconRakeBack} level="secondary" />,
    [],
  );
  const iconReferral = useMemo(
    () => <ThemeIcon IconComponent={IconReferral} level="secondary" />,
    [],
  );
  const iconLogout = useMemo(() => <ThemeIcon IconComponent={IconLogout} level="secondary" />, []);
  const iconVoucher = useMemo(
    () => <ThemeIcon IconComponent={IconVoucher} level="secondary" />,
    [],
  );
  const iconVault = useMemo(() => <ThemeIcon IconComponent={IconVault} level="secondary" />, []);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', (e) => {
      handleClose();
    });
    return () => {
      window.removeEventListener('scroll', (e) => {
        handleClose();
      });
    };
  }, [handleClose]);

  return (
    <>
      {/* <AccountCircleIcon
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        className={styles.accountIcon}
        onClick={handleClick}
      /> */}
      <div className={styles.vipIconFrame} onClick={handleClick}>
        <LazyLoadImage
          src={MobileImages.vipBig}
          alt=""
          width={33}
          height={33}
          className={styles.vipSmall}
        />
        <div className={styles.vipIconSmall}>
          <VipLevelFrame vipLevel={vipLevel} width={23} height={23} smallIcon={true} />
        </div>
      </div>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className={styles.dropdownHeader}>
          {/* <Avatar containerWidth={60} containerHeight={60} imageWidth={30} imageHeight={39} /> */}
          <div className={styles.vipIconFrame}>
            <LazyLoadImage
              src={MobileImages.vipBig}
              alt=""
              width={70}
              height={70}
              className={styles.vipSmall}
            />
            <div className={`${styles.vipIconBig} ${styles[`iconBig${vipLevel}`]}`}>
              <VipLevelFrame vipLevel={vipLevel} width={50} height={50} smallIcon={true} />
            </div>
          </div>
          <div className={styles.dropdownHeaderCol}>
            {nickName ? (
              <span className={styles.nickName}>{nickName}</span>
            ) : loginName ? (
              <span className={styles.nickName}>{loginName}</span>
            ) : null}
            <br />
            <span className={styles.vipprogress}>
              {intl.formatMessage({ id: 'topbar.vipProgress' })}
              <span className={styles.vipProgressSym}>:</span>
              <span className={styles.vipProgressTxt}>{vipLevelProgress}</span>
            </span>{' '}
          </div>
        </div>
        <div className={styles.menuItem1}>
          <ProfileMenuItem
            route={ROUTES.USER.MEMBER_CENTER.ROOT}
            label={intl.formatMessage({ id: 'topbar.member_center' })}
            icon={iconProfile}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.USER.MEMBER_CENTER.ROOT);
                  }
                : null
            }
          />
          <ProfileMenuItem
            route={ROUTES.WALLET.VAULT}
            label={intl.formatMessage({ id: 'topbar.vault' })}
            icon={iconVault}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.WALLET.VAULT);
                  }
                : null
            }
          />

          <ProfileMenuItem
            route={ROUTES.WALLET.DEPOSIT}
            label={intl.formatMessage({ id: 'topbar.deposit' })}
            icon={iconDeposit}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.WALLET.DEPOSIT);
                  }
                : null
            }
          />
          <ProfileMenuItem
            route={ROUTES.WALLET.WITHDRAWAL}
            label={intl.formatMessage({ id: 'topbar.withdraw' })}
            icon={iconWithdraw}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.WALLET.WITHDRAWAL);
                  }
                : null
            }
          />
          <ProfileMenuItem
            route={ROUTES.TRANSACTION.ROOT}
            label={intl.formatMessage({ id: 'topbar.transactions' })}
            icon={iconTransactions}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.TRANSACTION.ROOT);
                  }
                : null
            }
          />
          <ProfileMenuItem
            route={ROUTES.USER.VIP.MYVIP}
            label={intl.formatMessage({ id: 'topbar.vip' })}
            icon={iconRakeBack}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.USER.VIP.MYVIP);
                  }
                : null
            }
          />
          <ProfileMenuItem
            route={ROUTES.AFFILIATE.ROOT}
            label={intl.formatMessage({ id: 'topbar.affiliate' })}
            icon={iconReferral}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.AFFILIATE.ROOT);
                  }
                : null
            }
          />

          <ProfileMenuItem
            route={ROUTES.BONUS.VOUCHER}
            label={intl.formatMessage({ id: 'topbar.voucher' })}
            icon={iconVoucher}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.BONUS.VOUCHER);
                  }
                : null
            }
          />
        </div>
        {/* <Divider /> */}
        <div className={styles.menuItem2}>
          <ProfileMenuItem
            route={ROUTES.ACCOUNT.LOGOUT}
            label={intl.formatMessage({ id: 'topbar.logout' })}
            icon={iconLogout}
            labelClass={styles.vip}
            handleClose={handleClose}
            handleCloseGame={
              handleCloseGame
                ? (e) => {
                    handleCloseGame(e);
                    navigate(ROUTES.ACCOUNT.LOGOUT);
                  }
                : null
            }
          />
        </div>
      </StyledMenu>
    </>
  );
};

export default Profile;

const StyledMenu = styled((props) => (
  <Menu
    disableScrollLock={true}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    padding: '5px 15px',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.bg.sixth,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.border.secondary,
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      fontSize: 14,
      fontWeight: 500,
      color: theme.palette.text.secondary,
      minHeight: 'auto',
      paddingTop: 11,
      paddingBottom: 11,
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&.Mui-focusVisible': {
        backgroundColor: 'transparent',
      },
      '&+.MuiDivider-root': {
        marginTop: 6,
        marginBottom: 6,
      },
    },
  },
}));
