export const ACTIONS = {
  USER: {
    USER_DETAILS_SET: 'USER_DETAILS_SET',
    USER_LOGOUT: 'USER_LOGOUT',
    USER_BALANCE: 'USER_BALANCE',
    SPIN_CHANCE: 'SPIN_CHANCE',
    USER_INFO: 'USER_INFO',
    VERIFY_POPUP: 'VERIFY_POPUP',
    REMOVE_PHONE: 'REMOVE_PHONE',
    ACTIVE_SMS_PLATFORM: 'ACTIVE_SMS_PLATFORM',
    ISEMAIL_OTP_ACTIVE: 'ISEMAIL_OTP_ACTIVE',
  },
  COMMON: {
    AFFILIATESNACKBAR: 'AFFILIATESNACKBAR',
    WELCOMESNACKBAR: 'WELCOMESNACKBAR',
    THEME: 'THEME',
    COMMON_SET: 'COMMON_SET',
    COMMON_CLEAR: 'COMMON_CLEAR',
    SIDEBARDRAWEROPEN: 'SIDEBARDRAWEROPEN',
  },
  IP_ADDRESS: {
    IP_ADDRESS_SET: 'IP_ADDRESS_SET',
    IP_ADDRESS_CLEAR: 'IP_ADDRESS_CLEAR',
  },
  ERROR: {
    ERROR_SET: 'ERROR_SET',
    ERROR_CLEAR: 'ERROR_CLEAR',
  },
  MESSAGE: {
    MESSAGE_UNREAD_COUNT: 'MESSAGE_UNREAD_COUNT',
    MESSAGE_CLEAR: 'MESSAGE_CLEAR',
  },
  REFERRAL: {
    REFERRAL_SPIN_DETAILS: 'REFERRAL_SPIN_DETAILS',
    AWARD_SPIN_AMOUNT: 'AWARD_SPIN_AMOUNT',
    SHOW_WINNING_AMOUNT: 'SHOW_WINNING_AMOUNT',
    POPUP: 'POPUP_REFERRAL_BONUS',
    FLOATBUTTON: 'FLOATBUTTON_REFERRAL_BONUS',
    FIRSTSPINMODALOPEN: 'FIRST_SPIN_MODAL_OPEN',
    REFERRAL_BONUS_WON_AMOUNT: 'REFERRAL_BONUS_WON_AMOUNT',
  },
  DAILYLOGINBONUS: {
    POPUP: 'POPUP',
    FLOATBUTTON: 'FLOATBUTTON',
  },
  DAILYWEEKLYBONUS: {
    POPUP: 'POPUP_DAILY_WEEKLY_BONUS',
    FLOATBUTTON: 'FLOATBUTTON_DAILY_WEEKLY_BONUS',
  },
  POPUP: {
    DEPOSITAPPBONUS_POPUP: 'DEPOSITAPPBONUS_POPUP',
    DEPOSITAPPBONUS_FLOATBUTTON: 'DEPOSITAPPBONUS_FLOATBUTTON',
    ACTIVE_POPUP_LIST: 'ACTIVE_POPUP_LIST',
    POPUP_LIST: 'POPUP_LIST',
    SYSANNOUNCEMENTPOPUP: 'SYSANNOUNCEMENTPOPUP',
    SYSANNOUNCEMENTLIST: 'SYSANNOUNCEMENTLIST',
  },
  HOME: {
    CURRENTPOPUPORDER: 'CURRENTPOPUPORDER',
    WELCOMEPOPUP: 'WELCOMEPOPUP',
    MEMBERDEPOSITPOPUP: 'MEMBERDEPOSITPOPUP',
    ACTIVEPOPUPLIST: 'ACTIVEPOPUPLIST',
  },
  BONUS: {
    GETCLAIMABLEREWARD: 'GETCLAIMABLEREWARD',
    GETVIPDATA: 'GETVIPDATA',
    GETBONUSLIST: 'GETBONUSLIST',
    USERVIPINFO: 'USERVIPINFO',
    VIPLVLBONUSLIST: 'VIPLVLBONUSLIST',
    VIPCURRINDEX: 'VIPCURRINDEX',
  },
  WALLET: {
    GETDEPOSITCHANNELS: 'GETDEPOSITCHANNELS',
    DEPOSITRATE: 'DEPOSITRATE',
    BLOCKIFRAME: 'BLOCKIFRAME',
    DEPOSITDATA: 'DEPOSITDATA',
    PAYMENT_URL: 'PAYMENT_URL',
    WITHDRAWAL_DATA: 'WITHDRAWAL_DATA',
    WITHDRAWAL_INFO: 'WITHDRAWAL_INFO',
    WITHDRAWAL_ATTEMPTS_LEFT: 'WITHDRAWAL_ATTEMPTS_LEFT',
    WITHDRAWAL_RATES: 'WITHDRAWAL_RATES',
    WITHDRAWAL_ATTEMPTS_DAILY: 'WITHDRAWAL_ATTEMPTS_DAILY',
    WITHDRAW_INFO: 'WITHDRAW_INFO',
    BALANCE_DATA: 'BALANCE_DATA',
    HISTORY_DATA: 'HISTORY_DATA',
  },
  AFFILIATE: {
    DEFAULT_FILTER: 'DEFAULT_FILTER',
    DETAILED_DATA: 'DETAILED_DATA',
    ACTIVEGAMELIST: 'ACTIVEGAMELIST',
    COMMISSIONLIST: 'COMMISSIONLIST',
    NEWACTIVECOUNTCOMMISSION_DATA: 'NEWACTIVECOUNTCOMMISSION_DATA',
  },
  PROMOTION: {
    BONUS: 'BONUS',
    BONUS_FILTER: 'BONUS_FILTER',
  },
  GAME: {
    PROVIDERS_LIST: 'PROVIDERS_LIST',
    RECENT_GAME_List: 'RECENT_GAME_List',
  },
  BANNER: {
    BANNER_LIST: 'BANNER_LIST',
    SHORTCUT_LIST: 'SHORTCUT_LIST',
  },
  PUBLIC: {
    LIVE_SUPPORT_URL: 'LIVE_SUPPORT_URL',
    BASE_SETTING_DETAILS: 'BASE_SETTING_DETAILS',
    EXTERNAL_LINKS_LIST: 'EXTERNAL_LINKS_LIST',
    ISEMAIL_OTP_ACTIVE: 'ISEMAIL_OTP_ACTIVE',
  },
  COUNTDOWN: {
    VERIFY_COUNTDOWN_TIMER: 'VERIFY_COUNTDOWN_TIMER',
    REMOVED_TIMER: 'REMOVED_TIMER',
    TIMER: 'TIMER',
    IS_COUNTDOWN_DISABLED: 'IS_COUNTDOWN_DISABLED',
  },
};
