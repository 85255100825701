import IconEmail from 'component/mobile/common/icon/IconEmail';
import IconPhone from 'component/mobile/common/icon/IconPhone';
import IconIOS from 'component/mobile/common/icon/IconIOS';
import IconAndroid from 'component/mobile/common/icon/IconAndroid';
import IconUSDTBank from 'component/mobile/common/icon/IconUstdBank';
import IconIMPSBank from 'component/mobile/common/icon/IconIMPSBank';
import IconDeposit from 'component/mobile/common/icon/IconDeposit';
import IconWithdraw from 'component/mobile/common/icon/IconWithdraw';
import IconHistory from 'component/mobile/common/icon/IconHistory';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
export const EMAIL_PHONE_TAB = [
  {
    value: 'phone',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconPhone} iconActive={isActive} activeColor="black" />
    ),
  },
  {
    value: 'email',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconEmail} iconActive={isActive} activeColor="black" />
    ),
  },
];

export const ANDROID_IOS_TAB = [
  {
    value: 'android',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconAndroid} iconActive={isActive} activeColor="black" />
    ),
  },
  {
    value: 'iOS',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconIOS} iconActive={isActive} activeColor="black" />
    ),
  },
];

export const IMPS_USDT_TAB = [
  // {
  //   value: 'imps',
  //   icon: (isActive) => (
  //     <ThemeIcon IconComponent={IconIMPSBank} iconActive={isActive} activeColor="black" />
  //   ),
  // },
  {
    value: 'usdt',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconUSDTBank} iconActive={isActive} activeColor="black" />
    ),
  },
];

export const DEPOSIT_WITHDRAW_TAB = [
  {
    value: 'deposit',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconDeposit} iconActive={isActive} activeColor="black" />
    ),
  },
  {
    value: 'withdraw',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconWithdraw} iconActive={isActive} activeColor="black" />
    ),
  },
  {
    value: 'history',
    icon: (isActive) => (
      <ThemeIcon IconComponent={IconHistory} iconActive={isActive} activeColor="black" />
    ),
    hideText: true,
  },
];

export const REFERRAL_PROMOTION_TAB = [
  {
    value: 'lucky_user',
  },
  {
    value: 'registration',
  },
];
