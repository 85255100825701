import { ACTIONS } from '../constants/actions';

const initialState = {
  liveSupportUrl: null,
  baseSettingDetails: { baseSettingValue: 0 },
  externalLinksList: [],
  isEmailOTPActive: false,
};

export default function publicReducer(publics = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.PUBLIC.LIVE_SUPPORT_URL:
      return { ...publics, liveSupportUrl: payload };
    case ACTIONS.PUBLIC.BASE_SETTING_DETAILS:
      return { ...publics, baseSettingDetails: payload };
    case ACTIONS.PUBLIC.EXTERNAL_LINKS_LIST:
      return { ...publics, externalLinksList: payload };
    case ACTIONS.PUBLIC.ISEMAIL_OTP_ACTIVE:
      return { ...publics, isEmailOTPActive: payload };
    default:
      return publics;
  }
}
