const IconUSDTBank = ({ isActive, color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M0 9.38825L10 19.0908L20 9.38825L16.3636 1.49081H3.63636L0 9.38825ZM11.2857 8.07708C13.9814 8.21247 16 8.76631 16 9.42939C16 10.0925 13.9814 10.6463 11.2857 10.7817V15.8909H8.71429V10.7817C6.01857 10.6463 4 10.0925 4 9.42939C4 8.76631 6.01857 8.21247 8.71429 8.07708V6.66016H5.28571V3.89093H14.7143V6.66016H11.2857V8.07708ZM4.85714 9.31401C4.85714 9.88785 7.16 10.3525 10 10.3525C12.84 10.3525 15.1429 9.88785 15.1429 9.31401C15.1429 8.82939 13.5029 8.42478 11.2857 8.30939V9.85401C10.8743 9.8817 10.4457 9.89093 10 9.89093C9.55429 9.89093 9.12571 9.8817 8.71429 9.85401V8.30939C6.49714 8.42478 4.85714 8.83093 4.85714 9.31401Z"
            fill={color} />
    </svg>
  );
};

export default IconUSDTBank;

