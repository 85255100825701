import { MobileImages } from 'common/constants/image-resource';
import { ROUTES } from 'common/constants/routing';
import { getGameCatType } from 'common/helpers/helper';

export const SIDEBARENUM = {
  //id need to be changed for getting api data
  casino: {
    name: 'Live Casino',
    icon: MobileImages.home.casinoNavIcon,
    btnColor: '#025BE8',
    path: ROUTES.CASINO.ROOT,
    order: 0,
    width: 22,
    height: 22,
    subList: [
      {
        id: 0,
        icon: MobileImages.home.allIcon,
        name: 'All',
        searchParam: {
          catType: '999',
        },
      },
      // {
      //   id: 1,
      //   icon: MobileImages.home.blackjackIcon,
      //   name: 'Blackjack',
      //   searchParam: {
      //     catType: getGameCatType('BLACKJACK'),
      //   },
      // },
      {
        id: 2,
        icon: MobileImages.home.baccaratIcon,
        name: 'Baccarat',
        searchParam: {
          catType: getGameCatType('BACCARAT'),
        },
      },
      {
        id: 3,
        icon: MobileImages.home.rouletteIcon,
        name: 'Roulette',
        searchParam: {
          catType: getGameCatType('ROULETTE'),
        },
      },
    ],
  },
  slots: {
    name: 'Slots',
    icon: MobileImages.home.slotsNavIcon,
    btnColor: '#9526FF',
    path: ROUTES.SLOT.HOME,
    order: 1,
    width: 22,
    height: 22,
    subList: [
      {
        id: 0,
        icon: MobileImages.home.allIcon,
        name: 'All',
        searchParam: {
          catType: '999',
        },
        width: 24,
        height: 24,
      },
      // {
      //   icon: MobileImages.home.recentIcon,
      //   name: 'Recent',
      //   searchParam: {
      //     catType: getGameCatType('RECENT'),
      //   },
      //   loginCheck: true,
      //   width: 23,
      //   height: 24,
      // },
      // {
      //   icon: MobileImages.home.favoriteIcon,
      //   name: 'Favorite',
      //   searchParam: {
      //     catType: getGameCatType('FAVORITE'),
      //   },
      //   loginCheck: true,
      //   width: 20,
      //   height: 20,
      // },
      {
        id: 1,
        icon: MobileImages.home.newReleaseIcon,
        name: 'New Releases',
        searchParam: {
          catType: getGameCatType('NEW'),
        },
        width: 25,
        height: 24,
      },
      {
        id: 2,
        icon: MobileImages.home.popularIcon,
        name: 'Popular',
        searchParam: {
          catType: getGameCatType('POPULAR'),
        },
        width: 17,
        height: 24,
      },
      {
        id: 3,
        icon: MobileImages.home.bonusBuyIcon,
        name: 'Feature Buy In',
        searchParam: {
          catType: getGameCatType('Feature Buy In'),
        },
        width: 24,
        height: 24,
      },
    ],
  },
  // original: {
  //   name: 'Original',
  //   icon: MobileImages.home.originalIcon,
  //   btnColor: '#025BE8',
  //   path: ROUTES.ORIGINAL.ROOT,
  //   mainRedirect: true,
  // },
  // poker: {
  //   name: 'Poker',
  //   icon: MobileImages.home.pokerIcon,
  //   btnColor: '#025BE8',
  //   path: ROUTES.POKER.ROOT,
  //   mainRedirect: true,
  // },
  // sports: {
  //   name: 'Sports',
  //   icon: MobileImages.home.sportsNavIcon,
  //   btnColor: '#036331',
  //   path: ROUTES.TSBSPORTS.ROOT,
  //   order: 3,
  //   subList: [
  //     {
  //       id: 0,
  //       icon: MobileImages.home.liveEveIcon,
  //       name: 'LiveEvents',
  //       searchParam: {
  //         catType: 'live',
  //       },
  //     },
  //     {
  //       id: 1,
  //       icon: MobileImages.home.cricketIcon,
  //       name: 'Cricket',
  //       searchParam: {
  //         catType: 'cricket',
  //       },
  //     },
  //     {
  //       id: 2,
  //       icon: MobileImages.home.soccerIcon,
  //       name: 'Football',
  //       searchParam: {
  //         catType: 'football',
  //       },
  //     },
  //     {
  //       id: 3,
  //       icon: MobileImages.home.basketballIcon,
  //       name: 'Basketball',
  //       searchParam: {
  //         catType: 'basketball',
  //       },
  //     },
  //     {
  //       id: 4,
  //       icon: MobileImages.home.boxingIcon,
  //       name: 'Boxing',
  //       searchParam: {
  //         catType: 'boxing',
  //       },
  //     },
  //     {
  //       id: 5,
  //       icon: MobileImages.home.tennisIcon,
  //       name: 'Tennis',
  //       searchParam: {
  //         catType: 'tennis',
  //       },
  //     },
  //     {
  //       id: 6,
  //       icon: MobileImages.home.baseballIcon,
  //       name: 'Baseball',
  //       searchParam: {
  //         catType: 'baseball',
  //       },
  //     },
  //     {
  //       id: 7,
  //       icon: MobileImages.home.dotaIcon,
  //       name: 'Dota2',
  //       searchParam: {
  //         catType: 'dota2',
  //       },
  //     },
  //     {
  //       id: 8,
  //       icon: MobileImages.home.csgoIcon,
  //       name: 'CSGO',
  //       searchParam: {
  //         catType: 'csgo',
  //       },
  //     },
  //   ],
  // },
  sabasports: {
    name: 'Sports',
    icon: MobileImages.home.sportsNavIcon,
    btnColor: '#036331',
    path: ROUTES.SABASPORTS.ROOT,
    order: 3,
    subList: [
      // {
      //   id: 0,
      //   icon: MobileImages.home.liveEveIcon,
      //   name: 'LiveEvents',
      //   searchParam: {
      //     sportid: 'live',
      //   },
      // },

      {
        id: 2,
        icon: MobileImages.home.soccerIcon,
        name: 'Football',
        searchParam: {
          sportid: '1',
        },
      },
      {
        id: 3,
        icon: MobileImages.home.basketballIcon,
        name: 'Basketball',
        searchParam: {
          sportid: '2',
        },
      },
      // {
      //   id: 4,
      //   icon: MobileImages.home.boxingIcon,
      //   name: 'Boxing',
      //   searchParam: {
      //     sportid: '16',
      //   },
      // },
      {
        id: 5,
        icon: MobileImages.home.tennisIcon,
        name: 'Tennis',
        searchParam: {
          sportid: '5',
        },
      },
      // {
      //   id: 6,
      //   icon: MobileImages.home.baseballIcon,
      //   name: 'Baseball',
      //   searchParam: {
      //     sportid: '8',
      //   },
      // },
      {
        id: 7,
        icon: MobileImages.home.tableTennisIcon,
        name: 'TableTennis',
        searchParam: {
          sportid: '18',
        },
      },
      // {
      //   id: 8,
      //   icon: MobileImages.home.dotaIcon,
      //   name: 'Dota2',
      //   searchParam: {
      //     types: 'esports',
      //     gameid: 1
      //   },
      // },
      {
        id: 9,
        icon: MobileImages.home.csgoIcon,
        name: 'CSGO',
        searchParam: {
          types: 'esports',
          gameid: 3
        },
      },
      {
        id: 10,
        icon: MobileImages.home.lolIcon,
        name: 'LoL',
        searchParam: {
          types: 'esports',
          gameid: 2
        },
      },
    ],
  },
  Poker: {
    name: 'Poker',
    icon: MobileImages.home.pokerIcon,
    btnColor: '#025BE8',
    path: ROUTES.POKER.ROOT,
    order: 4,
    width: 22,
    height: 22,
    subList: [
      {
        id: 0,
        icon: MobileImages.home.pokerIcon,
        name: 'All',
        path: ROUTES.POKER.ROOT,
        pathParam: true,
      },
      {
        id: 1,
        icon: MobileImages.home.pokerIcon,
        name: 'NiuNiu',
        searchParam: {
          catType: getGameCatType('NIUNIU'),
        },
      },
      {
        id: 2,
        icon: MobileImages.home.pokerIcon,
        name: 'Blackjack',
        searchParam: {
          catType: getGameCatType('BLACKJACK'),
        },
      },
    ],
  },
};

export const SIDEBARGAMEENUM = {
  // rummy: {
  //   name: 'Rummy',
  //   icon: MobileImages.home.rummyIcon,
  //   btnColor: '#036331',
  //   path: ROUTES.GAME.GAMECAT,
  //   pathParam: true,
  //   order: 4,
  //   width: 16,
  //   height: 24,
  // },
  // teen_patti: {
  //   name: 'Teen Patti',
  //   icon: MobileImages.home.teenpattiIcon,
  //   btnColor: '#036331',
  //   path: ROUTES.GAME.GAMECAT,
  //   pathParam: true,
  //   order: 5,
  //   width: 21,
  //   height: 24,
  // },
  providers: {
    name: 'Providers',
    icon: MobileImages.home.providersIcon,
    btnColor: '#036331',
    path: ROUTES.PROVIDERS.ROOT,
    search: true,
    order: 6,
    width: 21,
    height: 24,
  },
  favourite: {
    name: 'Favourite',
    icon: MobileImages.home.favouriteIcon,
    btnColor: '#036331',
    path: ROUTES.FAVOURITE.ROOT,
    // loginCheck: true,
    search: true,
    // searchParam: {
    //   catType: getGameCatType('FAVOURITE'),
    // },
    order: 7,
    width: 21,
    height: 24,
  },
  recent: {
    name: 'Recent',
    icon: MobileImages.home.recentIcon,
    btnColor: '#036331',
    path: ROUTES.RECENT.ROOT,
    // loginCheck: true,
    search: true,
    // searchParam: {
    //   catType: getGameCatType('RECENT'),
    // },
    order: 8,
    width: 21,
    height: 24,
  },
  bonus: {
    name: 'Promotion',
    icon: MobileImages.home.bonusIcon,
    btnColor: '#036331',
    path: ROUTES.BONUS.ROOT,
    // loginCheck: true,
    search: true,
    // searchParam: {
    //   catType: getGameCatType('RECENT'),
    // },
    order: 9,
    width: 21,
    height: 24,
  },
  vip: {
    name: 'VIP',
    icon: MobileImages.home.vipIcon,
    btnColor: '#036331',
    path: ROUTES.USER.VIP.ROOT,
    // loginCheck: true,
    search: true,
    // searchParam: {
    //   catType: getGameCatType('RECENT'),
    // },
    order: 10,
    width: 21,
    height: 24,
  },
  affiliate: {
    name: 'Affiliate',
    icon: MobileImages.home.affiliateIcon,
    btnColor: '#036331',
    path: ROUTES.AFFILIATE.PARTNER,
    search: true,
    order: 10,
    width: 21,
    height: 24,
  },
  livesupport: {
    name: 'live_support',
    icon: MobileImages.home.livesupporttIcon,
    btnColor: '#036331',
    path: 'live support',
    search: true,
    order: 12,
    width: 21,
    height: 24,
  },
};

export const BANNERENUM = {
  luckyWheel: {
    name: 'LuckyWheel',
    icon: MobileImages.home.teenpattiIcon,
    btnColor: '#036331',
    path: ROUTES.HOME,
    order: 1,
  },
  cricket: {
    name: 'Cricket',
    icon: MobileImages.home.cricketBanner,
    btnColor: '#036331',
    path: ROUTES.HOME,
    pathParam: true,
    order: 2,
  },
  aviator: {
    name: 'Aviator',
    icon: MobileImages.home.aviatorBanner,
    btnColor: '#036331',
    path: ROUTES.GAME.GAMECAT,
    pathParam: true,
    order: 3,
  },
};
