const IconCloseCustomSnackbar = ({ color = '#939BB1', width = 15, height = 15 }) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.07178"
        y="0.000274658"
        width="19.6975"
        height="1.5152"
        rx="0.757598"
        transform="rotate(45 1.07178 0.000274658)"
        fill="#F5F5F5"
      />
      <rect
        x="14.9995"
        y="1.0714"
        width="19.6975"
        height="1.5152"
        rx="0.757598"
        transform="rotate(135 14.9995 1.0714)"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default IconCloseCustomSnackbar;
