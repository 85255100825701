import { useQuery, useMutation } from '@tanstack/react-query';
import { formatResult } from 'common/helpers/helper';
import { setProvidersList, setRecentGameList } from 'common/reducer-actions/gameActions';
import { useDispatch } from '../../store/StateProvider';
import GameApi from './api/gameApi';
import { setLocalStorage } from 'common/helpers/localStorage';
import { QUERY_KEYS } from 'common/constants/query-key';
import { STORAGE } from 'common/constants/keys';

/******************
 * Handle useQuery/useMutation
 ******************/

export const useGetMainPageCategoryGame = () => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [QUERY_KEYS.GAME.MAIN_PAGE_CATEGORY_GAME],
    queryFn: async () => {
      const res = await GameApi.fetchGetMainPageCategoryGame();
      if (res) {
        setProvidersList(dispatch, res.providers);
      }
      return res;
    },
  });
  return formatResult(query, true);
};

export const useLaunchGame = (silencer) => {
  const mutation = useMutation({
    mutationFn: (params) => GameApi.launchGame(params),
    meta: { preventGlobalError: silencer },
  });
  return formatResult(mutation);
};

export const useLaunchDemoGame = () => {
  const mutation = useMutation({
    mutationFn: (params) => GameApi.launchDemoGame(params),
  });
  return formatResult(mutation);
};

export const useGetSubGameList = (subGameType, enabled = true) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.GAME.SUBGAME_LIST, subGameType], // unique identifier in the query key to treats each call as unique, thus bypassing any cache
    queryFn: () => GameApi.getSubGameList(subGameType),
    // enabled: enabled,
  });
  return formatResult(query, true);
};

export const useGetRecentGamePlayed = (enabled = true) => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => GameApi.getRecentGamePlayed(),
    onSuccess: (res) => {
      if (res) {
        setRecentGameList(dispatch, res);
        return res;
      }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(mutation);
};

export const useGetGameCacheVersion = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.GAME.GAME_CACHE_VERSION],
    queryFn: async () => {
      const res = await GameApi.getGameCacheVersion();
      if (res >= 0) {
        setLocalStorage(STORAGE.GameListVersion, res);
      }
      return res;
    },
    refetchInterval: 60000,
  });
  return formatResult(query, true);
};

export const useGetFavGameList = () => {
  const mutation = useMutation({
    mutationFn: (params) => GameApi.getFavGameList(),
    meta: { preventGlobalError: true },
  });
  return formatResult(mutation);
};

export const useGetFavGameIdList = () => {
  const mutation = useMutation({
    mutationFn: (params) => GameApi.getFavGameIdList(),
    meta: { preventGlobalError: true },
  });
  return formatResult(mutation);
};

export const useToggleFavGameList = () => {
  const mutation = useMutation({
    mutationFn: (params) => GameApi.toggleFavGameList(params),
    meta: { preventGlobalError: true },
  });
  return formatResult(mutation);
};

export const useGetGameCategory = (enabled = true) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.GAME.GAME_CATEGORY],
    queryFn: GameApi.getGameCategory,
    enabled: enabled,
  });
  return formatResult(query, true);
};

export const useGetWinList = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.GAME.WIN_LIST],
    queryFn: GameApi.getWinList,
  });
  return formatResult(query, true);
};
