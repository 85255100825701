export const ROUTES = {
  HOST: window.APP_HOST || 'http://localhost:3000',
  ERROR: '/error',
  HOME: '/',
  ACCOUNT: {
    ROOT: '/account',
    REGISTER: '/account/register',
    LOGIN: '/account/login',
    VERIFY: '/account/verify-email',
    FORGOT_PASSWORD: '/account/forgot-password',
    RESET_PASSWORD: '/account/reset-password',
    LOGOUT: '/account/logout',
    THIRD_PARTY_UPDATE_INFO: '/account/update-primary-info',
    THIRD_PARTY_INSTRAGRAM_CALLBACK: '/account/instagram/callback',
    MAIL_CONFIRMATION: '/account/mail-confirmation',
    CREATE_ACCOUNT: '/account/create',
    UNSUBSCRIBE: '/account/unsubscribe',
  },
  USER: {
    MEMBER_CENTER: {
      ROOT: '/member-center',
      PERSONAL_DETAIL: '/member-center/personal-detail',
      VERIFY: '/member-center/verify-account',
      CHANGE_PASSWORD: '/member-center/change-password',
      FUNDING: '/member-center/funding',
    },
    VIP: {
      ROOT: '/vip',
      MYVIP: '/myvip',
    },
    LUCKY_WHEEL: {
      ROOT: '/lucky-wheel',
    },
    FREE_SPIN: {
      ROOT: '/freespin',
    },
  },
  WALLET: {
    DEPOSIT: '/wallet/deposit',
    DEPOSIT_USDT: '/wallet/depositresult',
    WITHDRAWAL: '/wallet/withdrawal',
    WITHDRAWALBINDING: '/wallet/withdrawalbinding',
    WITHDRAWALRESULT: '/wallet/withdrawalresult',
    WRAPPER: '/wallet/processing',
    VAULT: '/wallet/vault',
  },
  TRANSACTION: {
    ROOT: '/transaction',
  },
  MAINTENANCE: {
    ROOT: '/maintenance',
  },
  INTERCOM: {
    ROOT: '/cs',
  },
  CASINO: {
    ROOT: '/casino',
  },
  SLOT: {
    HOME: '/slots',
  },
  POLICY: {
    ROOT: '/policy',
  },
  TERMSERVICE: {
    ROOT: '/term-service',
  },
  DATADELETION: {
    ROOT: '/data-deletion',
  },
  USERAGREEMENT: {
    ROOT: '/user-agreement',
  },
  GAME: {
    GAMECAT: '/gamecat/:id',
  },
  EVENT: {
    JILI: '/event/jili',
  },
  FAVOURITE: { ROOT: '/favourite' },
  RECENT: { ROOT: '/recent' },
  AFFILIATE: {
    ROOT: '/affiliate',
    PARTNER: '/affiliate/partner',
    DETAILS: '/affiliate/:type',
  },
  BONUS: {
    ROOT: '/bonus',
    READ: '/bonus/:id',
    VOUCHER: '/bonus/voucher',
  },
  TSBSPORTS: {
    ROOT: '/tsbsports',
  },
  SABASPORTS: {
    ROOT: '/saba',
  },
  //tmp route
  SPORTS: {
    ROOT: '/sports',
  },
  POKER: {
    ROOT: '/poker',
  },
  ORIGINAL: {
    ROOT: '/original',
  },
  APP_INSTALATION: {
    ROOT: '/installation',
  },
  PROVIDERS: {
    ROOT: '/providers',
  },
  REFERRAL_PROMOTION: {
    ROOT: '/bonus/referral',
  },
  LIVE_SUPPORT: {
    ROOT: '/livesupport',
  },
};
