export const STORAGE = {
  UID: 'UID',
  AccessToken: 'Access-Token',
  RefreshToken: 'refreshToken',
  RememberMe: 'RememberMe',
  ExpiredTime: '_expiredTime',
  ReferralCode: 'AffKey', //referral code
  ReferralDomain: 'AffDomain', //referral domain url
  MemberReferralCode: 'MemberReferralCode',
  PopupBanner: 'PopupBanner',
  Theme: 'theme',
  ClickId: 'ClickId',
  HideIosModal: 'HideIosModal',
  GameListVersion: 'GameListVersion',
  AgeVerification: 'AgeVerification',
  RedirectRoute: 'RedirectRoute',
  AppsflyerAppId: 'AppsflyerAppId',
  ThirdPartyAppsflyerId: 'ThirdPartyAppsflyerId',
  FromUniApp: 'FromUniApp',
  AppView: 'AppView',
  WelcomePopUp: 'welcomePopUp',
  HideFreeSpinBtn: 'HideFreeSpinBtn',
  HideInviteReferralSpinBtn: 'HideInviteReferralSpinBtn',
  HideMemberDepositBonusPopUp: 'HideMemberDepositBonusPopUp',
  HideWelcomeBonusPopUp: 'HideWelcomeBonusPopUp',
  HideReferralBonusPopUp: 'HideReferralBonusPopUp',
  HideDailyLoginBonusPopUp: 'HideDailyLoginBonusPopUp',
  HideDailyWeeklyBonusPopUp: 'HideDailyWeeklyBonusPopUp',
  HideDepositAppBonusPopUp: 'HideDepositAppBonusPopUp',
  HideSysAnnouncementPopUp: 'HideSysAnnouncementPopUp',
  BannerListVersion: 'BannerListVersion',
  PopupBannerListVersion: 'PopupBannerListVersion',
  ShortcutListVersion: 'ShortcutListVersion',
  CountDownTimer: 'CountDownTimer',
};

export const COOKIE = {
  RefreshToken: 'refreshToken',
};

export const contentType = {
  form: 'application/x-www-form-urlencoded',
  json: 'application/json',
};

export const headerKeys = {
  ContentType: 'Content-Type',
  Authorization: 'Authorization',
};

export const METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  HEAD: 'HEAD',
  OPTIONS: 'OPTIONS',
};

export const ThirdParty = {
  GOOGLE: {
    PROVIDER: 1,
    NAME: 'Google',
    KEY: undefined,
  },
  FACEBOOK: {
    PROVIDER: 2,
    NAME: 'Facebook',
    KEY: window.FACEBOOK_KEY || undefined,
  },
  TELEGRAM: {
    PROVIDER: 3,
    NAME: 'Telegram',
    KEY: undefined,
  },
  // INSTAGRAM: {
  //   PROVIDER: 4,
  //   NAME: 'Instagram',
  //   KEY: window.INSTAGRAM_KEY || undefined,
  // },
};

export const LOBBYIDLIST = {
  LiveCasino: 1,
  Slots: 2,
  Sports: 3,
  Poker: 8,
  Original: 10,
};

export const SUBGAMEIDLIST = {
  LiveCasino: 1,
  Slots: 2,
  Sports: 3,
  Table: 4,
  ESports: 5,
  Fishing: 6,
  Animal: 7,
  Poker: 8,
  Lottery: 9,
};

export const GAMECATEGORY_TYPE = {
  POPULAR: 2,
  NEW: 3,
  RECOMMEND: 4,
  AVIATOR: 5,
  RUMMY: 6,
  'TEEN PATTI': 7,
  'Feature Buy In': 8,
  ORIGINAL: 9,
  POCKER: 10,
  BLACKJACK: 11,
  BACCARAT: 12,
  ROULETTE: 13,
  NIUNIU: 22,
  RECENT: 777,
  FAVOURITE: 888,
};

export const WITHDRAW_TYPE = {
  ONLINE: 1,
  OFFLINE: 2,
};

export const BINDING_TYPE = {
  ONLINE: 'online',
  GCASH: 'gcash',
  PAYMAYA: 'paymaya',
};

export const SEARCH_TYPE = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
  BETTING: 'BETHISTORY',
  ADJUSTMENT: 'ADJUSTMENT',
  BONUS: 'BONUS',
};

// export const SPORTCATEGORY_TYPE = {
//   LIVE: 'live',
//   CRICKET: 'cricket',
//   BASKETBALL: 'basketball',
//   BOXING: 'boxing',
//   TENNIS: 'tennis',
//   BASEBALL: 'baseball',
//   DOTA2: 'dota2',
//   CSGO: 'csgo',
// };
