import { useState } from 'react';
import CommonFrame from 'component/mobile/common/commonFrame/CommonFrame';
import NumFormat from 'component/mobile/common/numformat/NumFormat';
import SubmitButton from 'component/shared/buttons/SubmitButton';
import styles from './VaultTransfer.module.css';
import StyledTextField from 'component/shared/styledTextField/StyledTextField';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
import { InputAdornment } from '@mui/material';
import { AiFillEye } from '@react-icons/all-files/ai/AiFillEye';
import { AiFillEyeInvisible } from '@react-icons/all-files/ai/AiFillEyeInvisible';
import IconButton from '@mui/material/IconButton';
import IconPassword from 'component/mobile/common/icon/IconPassword';

const VaultTransfer = ({
  balanceData,
  intl,
  handleSubmit,
  formData,
  handleChange,
  setAll,
  bindingTab,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };
  return (
    <>
      <CommonFrame
        titleValue={
          bindingTab === 'deposit'
            ? intl.formatMessage({ id: 'vault.vault_deposit' })
            : intl.formatMessage({ id: 'vault.vault_withdrawal' })
        }
      >
        <div className={styles.vaultContainer}>
          {balanceData && (
            <>
              <div className={styles.text}>
                {bindingTab === 'deposit'
                  ? intl.formatMessage({ id: 'vault.currentBalance_text' })
                  : intl.formatMessage({ id: 'vault.vaultBalance_text' })}
              </div>
              <NumFormat
                value={
                  bindingTab === 'deposit'
                    ? balanceData.truncatedBalance || 0.0
                    : balanceData.truncatedVaultBalance || 0.0
                }
                prefix={' ' + window.CURRENCY_CODE}
                className={styles.numFormat}
              />
            </>
          )}

          <div className={styles.text}>
            {bindingTab === 'deposit'
              ? intl.formatMessage({ id: 'vault.depositAmt_text' })
              : intl.formatMessage({ id: 'vault.withdrawAmt_text' })}
          </div>
          <StyledTextField
            type="number"
            placeholder={intl.formatMessage({ id: 'deposit.enter_amount' })}
            value={formData.amount}
            onChange={handleChange('amount')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div
                    onClick={() => {
                      setAll(bindingTab === 'deposit');
                    }}
                    className={styles.allBtn}
                  >
                    {intl.formatMessage({ id: 'deposit.all_vault' })}
                  </div>
                </InputAdornment>
              ),
            }}
            sx={{
              input: {
                '&::placeholder': {
                  opacity: 1,
                  color: 'var(--mui-palette-text-tertiary)',
                  fontWeight: 500,
                },
              },
              '& .MuiFilledInput-root': {
                backgroundColor: 'var(--mui-palette-bg-fifth) !important',
              },
              '& .MuiFilledInput-input': {
                // color: 'var(--mui-palette-text-warning)',
                fontWeight: 600,
                paddingTop: 1,
                paddingBottom: 1,
              },
            }}
          />
        </div>
      </CommonFrame>
      {bindingTab !== 'deposit' && (
        <CommonFrame titleValue={intl.formatMessage({ id: 'login.password' })}>
          <div className={styles.text}>{intl.formatMessage({ id: 'vault.login_text' })}</div>
          <StyledTextField
            required
            value={formData.password}
            onChange={handleChange('password')}
            placeholder={intl.formatMessage({ id: 'login.password' })}
            sx={{
              input: {
                '&::placeholder': {
                  opacity: 1,
                  color: 'var(--mui-palette-text-tertiary)',
                  fontWeight: 500,
                },
              },
              '& .MuiFilledInput-root': {
                backgroundColor: 'var(--mui-palette-bg-fifth) !important',
              },
              '& .MuiFilledInput-input': {
                // color: 'var(--mui-palette-text-warning)',
                fontWeight: 600,
                paddingTop: 1,
                paddingBottom: 1,
              },
            }}
            InputProps={{
              type: showPassword ? 'text' : 'password',
              startAdornment: (
                <InputAdornment position="start">
                  <ThemeIcon IconComponent={IconPassword} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <ThemeIcon IconComponent={AiFillEye} />
                    ) : (
                      <ThemeIcon IconComponent={AiFillEyeInvisible} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </CommonFrame>
      )}
      <SubmitButton
        value={intl
          .formatMessage({ id: bindingTab === 'deposit' ? 'login.deposit' : 'login.withdraw' })
          .toUpperCase()}
        type="button"
        handleClick={handleSubmit}
        externalClassName={styles.btn}
      />
    </>
  );
};
export default VaultTransfer;
