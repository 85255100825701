import React, { useState, useContext } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom';
// import { useStore } from 'store/StateProvider';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import MuiAccordion from '@mui/material/Accordion';
import { styled, useTheme } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import { MobileImages } from 'common/constants/image-resource';
import IconMenu from 'component/mobile/common/icon/IconMenu';
import { Context } from 'component/shared/provider/IntlWrapper';
import useGetSidebarGame from 'services/hook/useGetSidebarGame';
import { BANNERENUM, SIDEBARENUM, SIDEBARGAMEENUM } from './SideberEnum';
import styles from './Sidebar.module.css';
import IconAndroid from 'component/mobile/common/icon/IconAndroid';
import IconIOS from 'component/mobile/common/icon/IconIOS';
import { isIOS } from 'common/helpers/helper';
import { ANDROID_IOS_TAB } from 'common/constants/tabs';
import { handlePromise } from 'common/helpers/helper';
import useFetchReferCode from 'services/hook/useFetchReferCode';
import { getGameCatType } from 'common/helpers/helper';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme, expanded }) => ({
  backgroundColor: expanded ? `${theme.palette.bg.secondary}` : 'transparent',
  border: `0px solid ${theme.palette.divider}`,
  borderRadius: 4,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor: '#0D1134',
  borderRadius: 4,
  marginTop: 5,
  alignItems: 'center',
}));

const useStyle = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  sidebarItem: {
    padding: '0px 15px !important',
    alignItems: 'center',
    display: 'flex',
    gap: 5,
    height: 38,
    '&:hover': {
      // backgroundColor: theme.palette.bg.secondaryColor,
      borderRadius: 57,
    },
  },
  drawerRoot: {
    // zIndex: theme.zIndex.modal,
    '& .MuiAccordionSummary-content': {
      gap: 5,
      margin: 0,
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px 15px !important',
      margin: '0px',
      height: 38,
      minHeight: 'unset',
    },

    '& .MuiAccordionSummary-root:focus': {
      // backgroundColor: theme.palette.bg.secondaryColor,
      height: 38,
      borderRadius: 57,
    },
    '& .MuiAccordionSummary-root:hover': {
      // backgroundColor: theme.palette.bg.secondaryColor,
      height: 38,
      borderRadius: 57,
    },
    '& .MuiAccordionDetails-root': {
      paddingLeft: 35,
      display: 'flex',
      padding: 0,
      height: 38,
      marginTop: 5,
    },
    '& .MuiAccordionDetails-root:hover': {
      // backgroundColor: theme.palette.bg.secondaryColor,
      height: 38,
      marginTop: 5,
      borderRadius: 57,
      display: 'flex',
    },
  },
  drawerTopSnackBar: {
    '&.MuiDrawer-root': {
      zIndex: 19,
      position: 'relative',
      overflow: 'auto',
      maxWidth: '700px',
      margin: 'auto',
    },
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 250,
      margin: 'auto',
      // backgroundColor: theme.palette.bg.fourthBgColor,
      '& .MuiBox-root': {
        padding: '10px 8px 20px',
      },
      '&.MuiDrawer-paper': {
        paddingTop: '105px',
      },
    },
  },
  drawerTopSnackBarWithoutSnackBar: {
    '&.MuiDrawer-root': {
      zIndex: 19,
      position: 'relative',
      overflow: 'auto',
      maxWidth: '700px',
      margin: 'auto',
    },
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 250,
      margin: 'auto',
      // backgroundColor: theme.palette.bg.fourthBgColor,
      '& .MuiBox-root': {
        padding: '10px 8px 20px',
      },
      '&.MuiDrawer-paper': {
        paddingTop: '60px',
      },
    },
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '8px 16px 2px',
}));

const Sidebar = (props) => {
  const { openDrawer, toggleDrawer, backButtonLayout, snackBar, isSports } = props;
  //Utils
  const isLoggedIn = isUserLoggedIn();
  const intl = useIntl();
  const theme = useTheme();
  const classes = useStyle();
  const navigate = useNavigate();
  const langContext = useContext(Context);
  const currentLocation = useLocation();
  //Axios hook
  const [checkGameLength, redirectToGame] = useGetSidebarGame();
  // const [store] = useStore();
  // const { user } = store;
  const [, , , appView] = useFetchReferCode();
  //State
  const [expandedAccordion, setExpandedAccordion] = useState(null);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  // const spinChances = user?.spinChances ? user?.spinChances : 0;

  const gotoPage = (item, subItem) => {
    toggleDrawer(false);

    // Handle affiliate redirection
    // if(subItem?.path === ROUTES.AFFILIATE.ROOT) {
    //   navigate(ROUTES.AFFILIATE[isLoggedIn ? "ROOT" : "PARTNER"]);
    //   return;
    // }

    if (!isLoggedIn && subItem?.loginCheck) {
      navigate(ROUTES.ACCOUNT.LOGIN);
    } else {
      if (subItem?.pathParam) {
        //for category game navigation
        subItem.path = subItem?.path.replace(':id', subItem?.name);
      }

      navigate({
        pathname: item.path ? item.path : subItem?.path,
        search: subItem?.searchParam ? `?${createSearchParams(subItem.searchParam)}` : null,
      });
    }
  };

  // const gotoHome = () => {
  //   setOpenDrawer(false);
  //   navigate(ROUTES.HOME);
  // };

  const handleGameCatRedirect = async (item) => {
    if (item.search && item.path === 'live support') {
      toggleDrawer(false);
      window.Intercom('show');
    } else if (item.search) {
      gotoPage({}, item);
    } else {
      const [data] = await handlePromise(checkGameLength(item.name));

      if (data[0] > 0) {
        toggleDrawer(false);
        redirectToGame(data[0], data[1]);
      } else {
        gotoPage({}, item);
      }
    }
  };

  // const openCricket = () => {
  //   toggleDrawer(false);
  //   navigate({
  //     pathname: ROUTES.TSBSPORTS.ROOT,
  //     search: `?${createSearchParams('catType=cricket')}`,
  //   });
  // };

  // const goToLuckyWheel = () => {
  //   toggleDrawer(false);
  //   navigate(ROUTES.USER.LUCKY_WHEEL.ROOT);
  // };

  const goToAGGame = () => {
    toggleDrawer(false);
    navigate(
      {
        pathname: ROUTES.CASINO.ROOT,
        search: `?${createSearchParams(`catType=${getGameCatType('BACCARAT')}&providerId=43`)}`,
      },
      { state: { hideFilter: true } },
    );
    // navigate(ROUTES.CASINO.ROOT, { state: { catType: getGameCatType('BACCARAT') } });
  };
  // const gotoInstallationPage = (selectedTab) => {
  //   const defaultTab = isIOS() ? ANDROID_IOS_TAB[1].value : ANDROID_IOS_TAB[0].value;
  //   const tabToNavigate = selectedTab || defaultTab;
  //   navigate(ROUTES.APP_INSTALATION.ROOT, { state: { selectedTab: tabToNavigate } });
  // };

  return (
    <div className={`${openDrawer ? 'active' : ''}`}>
      <Drawer
        anchor="left"
        open={openDrawer}
        variant="temporary"
        disableScrollLock
        onClose={toggleDrawer(false)}
        //to fix sidebarBottomContainer flash when drawer open
        sx={{
          [`& .MuiDrawer-paper`]: { boxSizing: 'border-box' },
        }}
        className={`${classes.drawerRoot} ${
          isSports || !backButtonLayout
            ? classes.drawerTopSnackBarWithoutSnackBar
            : !snackBar
            ? classes.drawerTopSnackBarWithoutSnackBar
            : classes.drawerTopSnackBar
        }`}
        id="sidebar-drawer"
        style={{
          ...(window.innerWidth > 700 && {
            transform: 'translateX(calc(-100% + 700px))',
            position: 'fixed',
            zIndex: 19,
          }),
          top: '-1px',
        }}
      >
        <Box role="presentation" sx={{ overflow: 'auto', scrollbarWidth: 'none' }}>
          <div className={styles.drawer}>
            <div className={styles.spinDiv}>
              {/* <img
                className={styles.banner_img}
                width={561}
                height={121}
                src={MobileImages.home.luckyWheelBanner}
                alt="Lucky Wheel"
                onClick={() =>
                  currentLocation.pathname !== ROUTES.USER.LUCKY_WHEEL.ROOT
                    ? goToLuckyWheel()
                    : toggleDrawer(false)
                }
              /> */}
              {/* <img
                className={styles.banner_img}
                width={206}
                height={52}
                src={MobileImages.home.cricketBanner}
                alt="Cricket"
                onClick={openCricket}
              />
              <img
                className={styles.banner_img}
                width={206}
                height={52}
                src={MobileImages.home.aviatorBanner}
                alt="Aviator"
                onClick={() => {
                  handleGameCatRedirect(BANNERENUM.aviator);
                }}
              /> */}
              <img
                className={styles.banner_img}
                width={206}
                height={52}
                src={MobileImages.home.agGameBanner}
                alt="AGGame"
                onClick={() => {
                  currentLocation.pathname !== ROUTES.CASINO.ROOT
                    ? goToAGGame()
                    : toggleDrawer(false);
                }}
              />
            </div>

            {Object.values(SIDEBARENUM).map((item, i) => {
              return (
                <Accordion
                  className={styles.accordion}
                  expanded={expandedAccordion === item.name}
                  key={i}
                  onChange={handleAccordionChange(item.name)}
                  onClick={
                    item.mainRedirect
                      ? () => {
                          navigate(item.path);
                        }
                      : undefined
                  }
                >
                  <AccordionSummary
                    expandIcon={item.subList ? <ExpandMoreIcon sx={{ color: '#565883' }} /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      className={styles.iconContainer}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <div
                        style={{
                          width: '4px',
                          height: '32px',
                          background: theme.palette.bg.quaternary,
                          borderRadius: '0px 4px 4px 0px',
                          display: expandedAccordion === item.name ? 'block' : 'none',
                          position: 'absolute',
                          left: '0px',
                        }}
                      />
                      <img
                        className={styles.iconImg}
                        src={item.icon}
                        width={item.width}
                        height={item.height}
                        alt={item.name}
                      />
                    </div>
                    <Typography className={styles.typotitle}>
                      {intl.formatMessage({ id: `sidebar.${item.name}` })}
                    </Typography>
                  </AccordionSummary>
                  {item.subList != null &&
                    item.subList.map((items, i) => {
                      return (
                        <AccordionDetails
                          key={i}
                          onClick={() =>
                            items.gameCatRedirect
                              ? handleGameCatRedirect(items)
                              : gotoPage(item, items)
                          }
                        >
                          <div className={styles.subItem}>
                            <div className={styles.subIconList}>
                              <img
                                className={styles.subIconImg}
                                src={items.icon}
                                alt={items.name}
                              />
                            </div>
                            <div className={styles.typo}>
                              <span> {intl.formatMessage({ id: `sidebar.${items.name}` })}</span>
                            </div>
                          </div>
                        </AccordionDetails>
                      );
                    })}
                </Accordion>
              );
            })}
            {Object.values(SIDEBARGAMEENUM).map((item, key) => {
              const hasDivider = item.border;
              return (
                <div
                  className={`${hasDivider ? styles.dividerBorder : ''}`}
                  key={key}
                  onClick={() => handleGameCatRedirect(item)}
                >
                  <div className={`${classes.sidebarItem}`}>
                    <div className={styles.itemImg}>
                      <img
                        src={item.icon}
                        className={styles.subIcon}
                        width={item.width}
                        height={item.height}
                        alt={item.name}
                      />
                    </div>
                    <div className={styles.itemTxt}>
                      <span className={styles.gameItemName}>
                        {intl.formatMessage({ id: `sidebar.${item.name}` })}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Box>
        {/* <div className={styles.sidebarBottomContainer}>
          <langContext.changeLangComponent />
        </div> */}
      </Drawer>
    </div>
  );
};

export default Sidebar;
