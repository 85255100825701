const zh = {
  app: {
    title: '太阳城亚洲',
    submit_btn: '提交',
    close_btn: '关闭',
    cancel_btn: '取消',
    back_btn: '返回',
    real_play_btn: '真实游戏',
    fun_play_btn: '试玩游戏',
    go_back_homepage_btn: '返回首页',
    home_btn: '首页',
    contactus_btn: '联系我们',
    contact_cs: '联系客服',
    game_brand: '太阳城亚洲',
    read_more: '阅读更多',
    read_less: '阅读缩小',
    provider_search_placeholder: '请输入游戏平台',
    no_result: '无结果',
    about: '大约',
    results: '个结果',
    copy_link: '链接已复制',
    copy_usdt_link: 'USDT地址已复制',
    copy: '已复制',
  },
  login: {
    deposit: '充值',
    withdraw: '提款',
    signin: '登录',
    email: '邮箱',
    phone: '手机',
    register: '注册',
    has_account: '新用户吗？',
    create_account: '创建一个账户',
    social_media: '通过社交媒体登录',
    username: '用户名 / 邮箱',
    password: '密码',
    forgot_ps: '忘记密码？',
    confirm_ps: '确认密码',
    successfully: '登录成功',
    invalid_username_email: '请输入至少6个字符',
    invalid_username: '请输入6-20位字母和数字',
    invalid_password: '密码无效',
    password_not_match: '密码和确认密码不一致',
    username_not_able_changed: '用户名提交后无法更改！',
    token_not_null: '访问令牌不能为空。',
    step_title1: '设置账户的最后一步',
    step_title2: '开始前，请输入您的用户名和密码。',
    signin_facebook: '通过Facebook登录',
    verify_acc: '验证账户',
    email_verified: '您的邮箱已验证！',
    login_here: '登入',
    invalid_verify_token: '验证码无效！',
    contact_customer_service: '请联系客服。',
  },
  forgotPassword: {
    forgotPassword: '忘记密码',
  },
  resetPassword: {
    title: '重置密码',
    token_not_found: '找不到重置密码的令牌',
  },
  topbar: {
    member_center: '会员中心',
    transactions: '交易记录',
    deposit: '充值',
    withdraw: '提款',
    vip: 'VIP & 奖励',
    affiliate: '代理',
    logout: '退出登录',
    signIn: '登录',
    signUp: '立即注册',
    vipProgress: 'VIP进度',
    welcomeBonusTile: '老虎机300%欢迎礼包高达 ￥3840',
    affiliateInviteTile: '成为太阳城亚洲的合作伙伴，获得中国最高佣金。',
    inviteBtn: '邀请',
    joinNowBtn: '立即加入',
    voucher: '兑换码',
    vault: '保险库',
  },
  home: {
    bet_directly: '直接投注',
    more_btn: '更多',
    title_popular: '热门',
    title_slots: '老虎机',
    title_casino: '赌场',
    table_games: '游戏',
    table_payout: '派彩',
    noShowPopUpText: '今天不再显示',
    all_bets: '在线投注',
  },
  sidebar: {
    bonus: '优惠促销',
    vip: 'VIP',
    live_support: '在线客服',
    Sports: '体育赛事',
    Slots: '老虎机',
    'Live Casino': '真人视讯',
    Rummy: '拉米牌',
    NiuNiu: '牛牛',
    'Teen Patti': 'Teen Patti',
    Poker: '棋牌',
    All: '全部',
    Recent: '最近',
    Favourite: '收藏',
    'New Releases': '新发布',
    Popular: '热门',
    'Feature Buy In': '特色买入',
    Blackjack: '21点',
    Baccarat: '百家乐',
    Roulette: '轮盘',
    download_app: '下载APP',
    Providers: '游戏平台',
    LiveEvents: '现场直播',
    Cricket: '板球',
    Football: '足球',
    Hockey: '曲棍球',
    Badminton: '羽毛球',
    Tennis: '网球',
    Wrestling: '摔跤',
    Basketball: '篮球',
    Baseball: '棒球',
    Boxing: '拳击',
    TableTennis: '乒乓球',
    LoL: 'LoL',
    Dota2: 'Dota2',
    CSGO: 'CSGO',
    Promotion: '优惠活动',
    VIP: 'VIP',
    Affiliate: '代理',
    Original: '原创游戏',
  },
  footer: {
    home: '首页',
    support: '在线客服',
    deposits: '存款',
    menu: '菜单',
    slots: '电子游戏',
    casino: '真人视讯',
    sports: '体育赛事',
    bonus: '优惠活动',
    aviator: 'Aviator',
    title: '太阳城亚洲',
    text1:
      '太阳城亚洲是中国玩家的完美在线赌场，适合想要玩真钱游戏的玩家。我们提供范围广泛的真钱在线赌场游戏，既适合高额投注，也适合低额投注，您可以根据预算找到合适的游戏。 \n\n此外，我们提供快速提款和方便的充值方式，使用可信赖的中国支付方式，如网银转账和微信支付或隐匿的加密货币USDT转账。 \n\n创建太阳城亚洲账户后，您可以清晰地查看您的资金情况，并且我们定期推出的促销活动能帮助您增加资金。 \n\n因此，如果您正在寻找一个安全可靠的真钱在线赌场，太阳城亚洲是您的完美选择。',
    join_community: '更多福利尽在官方渠道',
    app: '应用',
    sportsLobby: '体育赛事大厅',
    slotsLobby: '电子游戏大厅',
    casinoLobby: '真人视讯大厅',
    originalLobby: '原创游戏大厅',
    pokerLobby: '棋牌大厅',
    appDownload: 'App下载',
    entertainment: '娱乐',
    right_title: '快捷链接',
  },
  register: {
    createAcc: '创建账户',
    signUp: '注册',
    name: '姓名',
    username: '用户名',
    password: '密码',
    confirm_ps: '确认密码',
    email: '电子邮件',
    mobile_number: '手机号码',
    invite_code: '推荐码',
    captcha: '验证码',
    term1: '我同意 <link>用户协议</link>',
    term2: '，<link>隐私政策</link>',
    term3: '，<link>服务条款</link>，并确认我至少18岁',
    marketingTerm: '我同意接受太阳城的营销推广信息',
    btn: '注册',
    signin: '立即登录',
    has_account: '已经有帐户？',
    invalid_mobile: '无效的手机号码',
    invalid_password: '无效的密码',
    send_otp: '发送 OTP',
    phone_number: '电话号码',
    reg_username_warning: '用户名长度必须为6-20个字符，包含字母和数字',
    reg_username_charc_valid: '用户名必须包含字母和数字',
    reg_username_valid: '用户名必须至少为 6~20 个字符',
    reg_username_special_charc: '用户名不能包含特殊字符',
    ln_valid: '请输入 6-20 个字母数字字符',
    email_valid: '请输入有效的电子邮件',
    phone_valid: '请输入有效的电话号码',
    ps_valid: '请输入至少 6 个字符',
    confirm_ps_invalid: '请重新输入密码，确认密码必须与当前输入的密码相同',
    verify_otp: '请通过 OTP 验证您的手机号码',
    accept_terms: '请接受条款',
    otp_title1: 'OTP',
    otp_title2: '验证码',

    otp_placeholder: '请输入发送到您手机的 4 位数一次性密码 (OTP)',
    otp_label1: '这不是您的手机号码？',
    otp_label2: '您没有收到 OTP 吗？',
    otp_label3: '请稍候',
    otp_label4: '重新发送 OTP。',
    resend_otp: '重新发送 OTP',
    Verify_btn: '验证',
    mail_confirmation: '邮件确认',
    confirmation_text1:
      '我们已向 {email} 发送了一封验证邮件，请检查您的邮箱以完成邮箱验证并激活您的账户。',
    confirmation_text2: '电子邮件中的链接将在 5 分钟内过期。',
    missing_email1: '哎呀！看起来您在未启动注册流程的情况下访问了此页面。',
    missing_email2: '如果您正在尝试注册帐户，请点击提供的注册按钮以获取验证电子邮件。',
    missing_email3: '如需帮助或有任何疑问，请随时联系我们的支持团队。',
    create_account: '创建账户',
    user_agreement_title1: '用户协议',
    user_agreement_title2: '服务条款',
    user_agreement_content:
      '本最终用户协议（以下简称“协议”）在您使用太阳城亚洲的服务或产品之前，请务必完整阅读。请注意，该协议是您（以下简称“用户”或“您”）与太阳城亚洲（以下简称“太阳城亚洲”、“我们”或“本公司”）之间具有法律约束力的协议。太阳城亚洲负责拥有并运营其网站上的互联网平台及其所描述的游戏服务（以下简称“服务”）。通过点击“我同意”按钮（如有提供）和/或使用服务，您即表示同意遵守本协议中的条款与条件。',
    user_agreement_content1: '1. 授权许可',
    user_agreement_content1_1:
      '1.1. 根据本协议的条款和条件，太阳城亚洲授予用户一项非独占、个人、且不可转让的权利，允许您在个人计算机或其他能够访问互联网的设备上使用本服务，以访问提供的游戏。',
    user_agreement_content1_2:
      '1.2. 该服务不适用于以下个人：(i) 年龄未满 18 岁者；(ii) 未达到其所在管辖区法定成年年龄者；以及 (iii) 位于禁止访问该服务的管辖区内的个人。太阳城亚洲无法保证服务在所有管辖区的合法性，因此，确保使用服务的行为合法是用户自身的责任。',
    user_agreement_content1_3:
      '1.3. 太阳城亚洲及其许可方是服务及其代码、结构和组织的唯一所有者，享有包括版权、商业秘密、知识产权和其他相关权利。在适用法律允许的范围内，您不得：（a）复制、分发、发布、逆向工程、反编译、拆解、修改或翻译该网站；或（b）以法律或法规禁止的方式使用该服务（以上每一种情况均视为“未经授权的使用”）。太阳城亚洲保留所有未明确授予用户的权利，并保留对服务的所有权、所有权利及权益。您同意对因进行任何未经授权的使用而引起的任何损害、费用或开支承担全部责任。若您意识到任何人进行未经授权的使用，您应立即通知太阳城亚洲，并在太阳城亚洲进行调查时提供合理的协助。',
    user_agreement_content1_4:
      '1.4. “太阳城亚洲”一词、其域名及太阳城亚洲作为服务的一部分使用的任何其他商标或服务标记（以下简称“商标”）均为太阳城亚洲的独有财产。此外，网站上的所有内容，包括但不限于图像、图片、图形、照片、动画、视频、音乐、音频和文本（以下简称“网站内容”）均属于太阳城亚洲，并受到版权和/或其他知识产权或其他相关权利的保护。您特此承认，通过使用该服务，您并未获得网站内容和/或商标的任何权利或其任何部分。除非事先获得太阳城亚洲的书面同意，您不得以任何形式使用网站内容和/或商标。您进一步同意，不得进行任何可能损害或妨碍太阳城亚洲权利，包括其知识产权的行为。',
    user_agreement_content2: '2. 不作担保',
    user_agreement_content2_1:
      '2.1. 太阳城亚洲否认与服务相关的所有明示或暗示的担保，该服务以“现状”提供。我们不对服务的质量、适用性、完整性或准确性作出任何形式的担保或声明。',
    user_agreement_content2_2:
      '2.2. 尽管太阳城亚洲会尽力提供服务，但我们不保证该服务不会中断、始终及时或无错误，亦不保证任何缺陷会被及时修复。',
    user_agreement_content3:
      '3. 权限/服务条款 您同意遵守太阳城亚洲网站上所列明的游戏规则。太阳城亚洲保留对服务的发布、维护及终止的完全权利。太阳城亚洲管理层关于服务使用或争议解决的决定为最终裁定，不可进行审查或上诉。',
    user_agreement_content4: '4. 作为玩家的责任',
    user_agreement_content4_1: '4.1. 您在此声明并保证：',
    user_agreement_content4_1_1:
      '4.1.1. 您已年满18岁，或达到您居住地管辖区内规定的更高法定成年年龄（例如，爱沙尼亚为21岁），并且根据适用于您的法律，您有合法资格参与网站上提供的游戏。',
    user_agreement_content4_1_2: '4.1.2. 您仅以个人非职业身份参与游戏，且仅出于娱乐和消遣的目的。',
    user_agreement_content4_1_3: '4.1.3. 您是以个人身份参与游戏，而非代表任何其他人。',
    user_agreement_content4_1_4:
      '4.1.4. 您在本协议有效期内提供给太阳城亚洲的所有信息均为真实、完整和准确的，并且在信息发生变化时，您应及时通知太阳城亚洲。',
    user_agreement_content4_1_5:
      '4.1.5. 您对根据相关法律规定，负责对从太阳城亚洲获得的任何奖金进行报税和会计处理。',
    user_agreement_content4_1_6:
      '4.1.6. 您理解，通过参与游戏，您将面临损失存入会员账户的虚拟资金的风险。',
    user_agreement_content4_1_7:
      '4.1.7. 您不得参与任何欺诈、串通、操控或其他非法活动，无论是与您本人或第三方有关，且不得使用任何软件、辅助方法、技术或硬件设备来参与任何游戏。太阳城亚洲保留在发生此类行为时宣布任何投注无效的权利。',
    user_agreement_content4_1_8:
      '4.1.8. 您理解，比特币作为虚拟资金，并不被视为法定货币或现金，因此在网站上它们被视为没有内在价值的虚拟资金。',
    user_agreement_content4_1_9: '4.1.9. 您理解，比特币的价值可能会根据市场波动而发生剧烈变化。',
    user_agreement_content4_1_10: '4.1.10. 您不得使用属于第三方或他人的任何支付方式。',
    user_agreement_content4_2: '4.2. 您不得转让、出售和/或获取用户账户。',
    user_agreement_content4_3:
      '4.3. 在我们的网站上玩游戏应与在其他任何环境中玩游戏的方式相同。这意味着玩家应互相尊重，避免发表粗鲁或不雅的评论。',
    user_agreement_content4_4:
      '4.4. 可能会出现某些情况，导致我们的投注确认或支付出现错误。在这些情况下，太阳城亚洲保留取消所有包含此类错误的投注的权利。',
    user_agreement_content4_5:
      '4.5. 如果用户意识到软件中可能存在的错误或不完整之处，他/她同意不利用这些错误。此外，用户同意立即向太阳城亚洲报告任何错误或不完整。如果用户未能履行本条款中规定的义务，太阳城亚洲有权要求用户全额赔偿与该错误或不完整相关的所有费用，包括任何因用户未能及时通知而产生的费用。',
    user_agreement_content4_6:
      '4.6. 如果游戏已开始但因系统故障而未能继续进行，太阳城亚洲应通过将投注金额返还至用户账户，或在账户不存在时以批准的方式支付给用户；如果用户在游戏未能进行时有累积信用，太阳城亚洲应将该信用的货币价值记入用户账户，或在账户不存在时以批准的方式支付给用户。',
    user_agreement_content4_7:
      '4.7. 太阳城亚洲保留拒绝或限制投注的权利。用户不得投注超过其个人账户余额的金额。所有奖金将记入用户的个人账户。',
    user_agreement_content4_8:
      '4.8. 如果怀疑或有证据表明存在操控赌场系统的行为，太阳城亚洲保留暂停付款的权利。对于任何操控或试图操控赌场系统的用户或其他人员，太阳城亚洲将提起刑事指控。此外，太阳城亚洲保留终止和/或更改网站上提供的任何游戏或事件的权利。',
    user_agreement_content4_9:
      '4.9. 如果存在可疑或欺诈交易，太阳城亚洲保留要求提供某些验证信息的权利。',
    user_agreement_content4_10:
      '4.10. 太阳城亚洲保留部分或全部取消投注的权利，如果太阳城亚洲自行裁定发生以下任何情况：',
    user_agreement_content4_10_1:
      '4.10.1. 您或与您有关联的人可能直接或间接地影响事件结果，以获取非法利益。',
    user_agreement_content4_10_2: '4.10.2. 您或与您有关联的人直接或间接地规避太阳城亚洲的规则。',
    user_agreement_content4_10_3: '4.10.3. 事件结果受到犯罪活动的直接或间接影响。',
    user_agreement_content4_10_4: '4.10.4. 在网站因技术问题受到影响期间，接受了本不应接受的投注。',
    user_agreement_content4_10_5:
      '4.10.5. 由于错误（例如印刷错误、技术问题、不可抗力等），投注因失误被提供、下注或接受。',
    user_agreement_content4_10_6:
      '4.10.6. 如果玩家的存款费用过低，导致被区块链或类似网站标记为‘手续费不足’，且太阳城亚洲自行裁定玩家的交易和行为具有欺诈性质，太阳城亚洲有权没收奖金。',
    user_agreement_content4_11:
      '4.11. 如果您与任何赌博平台签订了自我排除协议，您应立即通知太阳城亚洲。',
    user_agreement_content5: '5. 禁止使用',
    user_agreement_content5_1:
      '5.1. "服务仅供用户个人使用。用户只能为个人娱乐目的进行下注，不得创建多个账户以进行串通和/或滥用服务。',
    user_agreement_content5_2:
      '5.2. 司法管辖区： 位于阿鲁巴、博内尔、库拉索、哥斯达黎加、法国、荷兰、萨巴、圣尤斯达提乌斯、圣马丁或美国（统称“禁止管辖区”）的居民不得使用本服务。为明确起见，此限制同样适用于居住在上述禁止管辖区的其他国家或地区的公民。任何试图绕过这些地区限制的行为均构成违反本协议。同时，任何操控太阳城亚洲定位信息或提供虚假、误导性位置信息的行为，将被视为违约行为。',
    user_agreement_content6: '6. 了解您的客户（“KYC”）',
    user_agreement_content6_1:
      '6.1. 太阳城亚洲 保留在任何时候要求用户提供 KYC 信息的权利，以验证用户的身份和位置。在身份确认完成之前，太阳城亚洲 保留限制服务和支付的权利。',
    user_agreement_content7: '7. 违规',
    user_agreement_content7_1:
      '7.1. 在不损害其他权利的情况下，如果用户部分或全部违反本协议中的任何条款，太阳城亚洲 保留终止本合同或与该用户签订的其他合同的权利，并/或对该用户采取法律行动。',
    user_agreement_content7_2:
      '7.2. 您同意使 太阳城亚洲 及其股东、董事、代理人和员工免于因以下原因引起的任何索赔、责任、损害、损失、成本和费用：(i) 您部分或全部违反本协议；(ii) 您违反法律或第三方权利；(iii) 或因您使用服务而引起的任何索赔。',
    user_agreement_content8: '8. 责任限制',
    user_agreement_content8_1:
      '8.1. 无论任何情况下，太阳城亚洲 均不对使用或滥用服务所引发的任何直接、间接、附带、特殊或衍生损害承担责任，包括但不限于业务利润损失、业务中断、信息丢失或其他经济损失，即使 太阳城亚洲 事先已被告知可能发生此类损害。',
    user_agreement_content8_2:
      '8.2. 本协议中的任何内容均不排除或限制因 太阳城亚洲 过失导致的死亡或人身伤害的责任。',
    user_agreement_content9: '9. 争议',
    user_agreement_content9_1:
      '如果用户想要提交投诉，请联系 太阳城亚洲 客户服务，邮箱为 support@太阳城亚洲。如果任何争议未能按照您的满意度得到解决，您可以向以下管理辖区寻求救济。',
    user_agreement_content10: '10. 修改',
    user_agreement_content10_1:
      '太阳城亚洲 保留随时或以其他方式更改本合同或其任何部分的权利，无需提前通知您，您将受发布后您使用服务时适用的合同版本的约束。因此，我们鼓励您查看当前适用合同版本的条款和条件。您继续使用服务将被视为您对本合同任何修改的接受。',
    user_agreement_content11: '11. 管辖法律',
    user_agreement_content11_1:
      '本合同或与本合同相关的任何事项应根据哥斯达黎加法律进行管理和解释。您不可撤销地同意，根据以下条款，因本合同引起的任何索赔、争议或分歧，以及由此产生的任何事项，应由哥斯达黎加法院拥有专属管辖权，并且您不可撤销地放弃任何将本案提交至不方便法庭的权利，或主张这些法院无管辖权。本条款中的任何内容均不限制 太阳城亚洲 在任何其他管辖权的法院对您提起诉讼的权利，或在该管辖权法律允许的范围内对任何其他一个或多个管辖区提起诉讼，无论是并行进行还是不并行进行。',
    user_agreement_content11_2:
      '如果本合同的任何条款在任何管辖区内被认定为非法、无效或不可执行，则该条款在该管辖区的有效性和可执行性不会影响此处其他条款的有效性和可执行性，或该条款在其他管辖区的有效性和可执行性。',
    user_agreement_content13: '13. 转让',
    user_agreement_content13_1:
      '太阳城亚洲 保留在未提前通知的情况下将本合同部分或全部转让给任何人的权利。用户不得将其在本合同下的权利或义务转让给任何人。',
    user_agreement_content14: '14. 利益游戏',
    user_agreement_content14_1:
      '如果赌场发现任何用户接受奖金或促销仅仅是为了通过已知的方式利用奖金回报而试图兑现奖金，或以任何方式试图利用太阳城亚洲提供的奖金，则太阳城亚洲将立即没收该用户的 winnings 并关闭其账户，保留扣留任何进一步取款的权利。例如，利用优势游戏的情况包括在没有更多的投注要求时，延迟在任何游戏中的任何游戏回合，包括免费旋转功能和奖金功能，或者在游戏中仍可用免费旋转功能或奖金功能时进行新的存款。在公平游戏的原则下，平等、零或低边际投注或对冲投注将被视为不规则游戏，针对奖金投注要求而言。如果赌场认为发生了不规则游戏，赌场保留扣留任何取款和/或没收所有 winnings 的权利。',
    user_agreement_def_1: '定义；',
    user_agreement_def_2: "太阳城亚洲称为'我们'或'我们'。",
    user_agreement_def_3: "玩家称为'您'或'玩家'。",
    user_agreement_def_4: "'网站'是指通过桌面、移动或玩家使用的其他平台访问的太阳城亚洲。",
    user_agreement_def_5: 'https://SunGame/help/terms-service',
    user_agreement_gen_1: '1. 总则',
    user_agreement_gen_1_1: '1.1. 本用户协议适用于通过太阳城亚洲访问的游戏的使用。',
    user_agreement_gen_1_2:
      '1.2. 本用户协议自您完成注册过程时生效，包括勾选框接受本用户协议并成功创建账户。通过使用账户创建后网站的任何部分，您同意本用户协议。',
    user_agreement_gen_1_3:
      '1.3. 在创建账户之前，您必须仔细阅读本用户协议的全部内容。如果您不同意本用户协议的任何条款，您不得创建账户或继续使用网站。',
    user_agreement_gen_1_4:
      '1.4. 我们有权随时对本用户协议进行修改而无需提前通知。如果我们进行此类修改，我们可能采取适当措施将这些更改告知您（例如通过电子邮件或在网站显著位置放置通知，以及修订后的用户协议），但您有责任检查任何修改、更新和/或变更。您在本用户协议任何修改后继续使用太阳城亚洲的服务和网站将被视为您接受并同意受该等修改、更新和/或变更的约束。',
    user_agreement_gen_1_5:
      '1.5. 本用户协议可能以多种语言发布，以便玩家方便查阅。中文版本是您与我们之间关系的唯一法律依据，若任何翻译存在差异，应以本用户协议的英文版本为准。',
    user_agreement_gen_2: '2. 约束性声明',
    user_agreement_gen_2_1:
      '2.1. 通过同意受本用户协议约束，您还同意受太阳城亚洲规则和隐私政策的约束，这些规则和政策在此被引用纳入本用户协议。如果存在任何不一致之处，则本用户协议应优先。您在此声明并保证：',
    user_agreement_gen_2_1_1:
      '2.1.1. 您已年满(a) 18岁或(b) 适用于您的任何法律所规定的其他法定年龄或成年年龄，以较大者为准；',
    user_agreement_gen_2_1_2:
      '2.1.2. 您有完全能力与我们签订具有法律约束力的协议，且没有任何形式的法律能力限制；',
    user_agreement_gen_2_1_3:
      '2.1.3. 您在本协议有效期内提供给我们的所有信息均为真实、完整、正确，并且您应立即通知我们任何信息的变化；',
    user_agreement_gen_2_1_4:
      '2.1.4. 您对任何从我们处获得的 winnings 应报告并负责支付相关法律规定的税款；',
    user_agreement_gen_2_1_5:
      '2.1.5. 您理解通过使用我们的服务，您承担存入会员账户的资金损失的风险，并接受对此类损失的全部责任；',
    user_agreement_gen_2_1_6: '2.1.6. 您在所在司法管辖区内被允许使用在线赌场服务；',
    user_agreement_gen_2_1_7:
      '2.1.7. 在存入和提取资金到您的会员账户时，您仅使用合法并且合法拥有的加密货币；',
    user_agreement_gen_2_1_8: '2.1.8. 您理解，加密货币的价值可能会根据市场价值剧烈变化；',
    user_agreement_gen_2_1_9:
      '2.1.9. 我们提供给您的计算机软件、计算机图形、网站和用户界面均由太阳城亚洲或其关联方拥有，并受版权法保护。您只能根据我们制定的所有规则和用户协议以及所有适用的法律、规则和法规，将软件用于个人和娱乐用途；',
    user_agreement_gen_2_1_10:
      '2.1.10. 您理解，加密货币不被视为法定货币，因此在网站上它们被视为没有内在价值的虚拟资金。',
    user_agreement_gen_2_1_11:
      '2.1.11. 您确认您不是太阳城亚洲的官员、董事、员工、顾问或代理人，亦不是与太阳城亚洲相关的任何公司的员工，或上述人员的亲属或配偶；',
    user_agreement_gen_2_1_12:
      '2.1.12. 您没有被诊断为或分类为强迫性或问题赌博者。我们不对您在使用我们服务时出现的任何问题赌博负责，但会努力告知您相关的帮助资源。如果我们认为此类行动有益，保留实施冷却期的权利。',
    user_agreement_gen_2_1_13:
      '2.1.13. 您接受并承认我们保留检测和防止使用禁止技术的权利，包括但不限于欺诈交易检测、自动注册和注册、游戏玩法和屏幕捕捉技术。这些措施可能包括但不限于对玩家设备属性的检查、地理位置检测和IP掩蔽、交易和区块链分析；',
    user_agreement_gen_2_1_14:
      '2.1.14. 您接受我们有权终止和/或更改网站上提供的任何游戏或活动，并拒绝和/或限制投注。',
    user_agreement_gen_2_1_15:
      '2.1.15. 您接受我们有权禁止/封锁多个账户并自由控制这些账户中的资产。',
    user_agreement_gen_2_1_16:
      '2.1.16. 您意识到软件中可能存在错误或不完整的情况，您同意不利用这些情况。此外，您同意立即向太阳城亚洲报告任何错误或不完整。如果您未能履行本条款所述的义务，太阳城亚洲有权对与错误或不完整相关的所有费用进行全面索赔，包括与相关错误/不完整相关的任何费用和用户未能通知的费用。',
    user_agreement_gen_2_1_17:
      '2.1.17. 您知道太阳城亚洲有权进行“了解您的客户”（KYC）验证程序。如果我们确定您提供了虚假或误导性的信息，则可能会阻止或关闭您访问用户账户。',
    user_agreement_gen_2_2:
      '2.2. 如果太阳城亚洲自行决定认为以下任何情况显而易见，我们保留宣布投注部分或全部无效的权利：',
    user_agreement_gen_2_2_1:
      '2.2.1. 您或与您有关联的人可能直接或间接影响事件的结果，以获取非法优势。',
    user_agreement_gen_2_2_2: '2.2.2. 您和/或与您有关联的人直接或间接规避太阳城亚洲的规则。',
    user_agreement_gen_2_2_3: '2.2.3. 事件的结果受到犯罪活动的直接或间接影响。',
    user_agreement_gen_2_2_4:
      '2.2.4. 由于技术问题，投注在其他情况下不会被接受，但在网站受到影响的期间内被接受。',
    user_agreement_gen_2_2_5:
      '2.2.5. 由于错误，例如失误、漏洞、技术错误、不可抗力或其他原因，投注因该错误而被提供、下达和/或接受。',
    user_agreement_gen_2_2_6:
      '2.2.6. 如果玩家的存款费用过低，并被区块链或类似网站标记为“费用不足以转发”，如果太阳城亚洲自行判断该交易和玩家的行为具有欺诈性质，太阳城亚洲保留没收 winnings 的权利。',
    user_agreement_gen_3: '3. 限制区域',
    user_agreement_gen_3_1:
      '3.1. 黑名单区域： 中国、荷兰、荷兰加勒比海岛屿、匈牙利、澳大利亚、安大略（加拿大）、库拉索、法国、美国和/或任何其他受法律限制的国家或州。请注意，在上述黑名单国家进行太阳城亚洲游戏是严格禁止的。您同意在此披露您的个人数据，以便执行他们的职责并为您提供尽可能最佳的帮助和服务。您在此同意此类披露。',
    user_agreement_gen_4: '4. 一般投注规则',
    user_agreement_gen_4_1: '4.1. 只有注册账户持有者可以进行投注。',
    user_agreement_gen_4_2: '4.2. 投注只能通过互联网进行。',
    user_agreement_gen_4_3: '4.3. 只有在您的太阳城亚洲账户中有足够余额时，您才能进行投注。',
    user_agreement_gen_4_4: '4.4. 一旦投注达成，将受当时可在网站上获取的有效用户协议版本的约束。',
    user_agreement_gen_4_5:
      '4.5. 赢得的投注的任何支付款项将记入您的账户，由本金乘以投注时的赔率构成。',
    user_agreement_gen_4_6:
      '4.6. 如果太阳城亚洲在其自行判断中确定该支付款项因错误而被记入太阳城亚洲账户，太阳城亚洲保留调整该支付款项的权利。',
    user_agreement_gen_4_7: '4.7. 已下达并被接受的投注，您不得更改、撤回或取消。',
    user_agreement_gen_4_8: '4.8. 您可以在网站上查看所有投注的列表、状态和详细信息。',
    user_agreement_gen_4_9:
      '4.9. 当您进行投注时，您承认您已完全阅读并理解关于该投注的所有用户协议，如在网站上所述。',
    user_agreement_gen_4_10:
      '4.10. 太阳城亚洲管理您的账户，并计算可用资金、待处理资金、投注资金以及奖金金额。除非有证据证明，否则这些金额视为最终并被视为准确。',
    user_agreement_gen_4_11: '4.11. 您对所下的投注负全部责任。',
    user_agreement_gen_4_12: '4.12. 赢得的金额将在最终结果确认后支付到您的账户。',
    user_agreement_gen_5: '5. 奖金和促销',
    user_agreement_gen_5_1:
      '5.1. 太阳城亚洲 保留随时取消任何促销、奖金或奖金计划的权利（包括但不限于充值奖励、邀请朋友奖励奖金和忠诚计划），如果我们认为该奖金设置不正确或被滥用；如果该奖金已支付，我们保留拒绝任何提款请求并从您的账户中扣除该金额的权利。是否认为奖金设置不正确或被滥用，完全由 太阳城亚洲 决定。',
    user_agreement_gen_5_2:
      '5.2. 如果您使用存款奖金，在您达到存款奖金用户协议中规定的要求之前，您的原始存款将不被接受提款。',
    user_agreement_gen_5_3:
      '5.3. 如果违反任何优惠或促销条款，或有证据表明某位客户或客户群体因存款奖金、增强支付、免费投注、无风险投注或其他任何促销优惠而获得保证的客户利润，无论是单独还是作为一个群体，太阳城亚洲 保留追回此类优惠奖金部分的权利，并可自行决定以正确的赔率结算投注，作废免费投注和无风险投注或作废任何由存款奖金资助的投注。此外，太阳城亚洲 保留对客户收取高达存款奖金、免费投注奖金、无风险投注或额外支付的管理费用的权利，以覆盖管理成本。我们进一步保留要求任何客户提供足够的文件，以使我们完全满意于客户身份的权利，方可在其账户中记入任何奖金、免费投注、无风险投注或优惠。',
    user_agreement_gen_5_4:
      '5.4. 所有 太阳城亚洲 的优惠旨在供休闲玩家使用，太阳城亚洲 可以自行决定限制客户参与所有或部分促销的资格。',
    user_agreement_gen_5_5: '5.5. 太阳城亚洲 保留自行决定修改、取消、追回或拒绝任何促销的权利。',
    user_agreement_gen_5_6:
      '5.6. 奖金只能在每个人/账户、家庭、地址、电子邮件地址、IP 地址和共享计算机的环境（大学、兄弟会、学校、公共图书馆、工作场所等）中获得一次。如果发现滥用/欺诈的证据，运营商保留关闭您的账户并没收任何现有资金的权利。',
    user_agreement_gen_5_7:
      '5.7. 您承认并理解与促销、奖金和特别优惠相关的单独用户协议存在，并且这些协议是本用户协议的补充。本用户协议在本网站的相关内容页面上列出，或已根据情况提供给您。如果此类促销、奖金和特别优惠的条款与本用户协议的条款发生冲突，则以此类促销、奖金和特别优惠的条款为准。',
    user_agreement_gen_5_8:
      '5.8. 我们可能会要求您在使用我们为您账户记入的任何免费/奖金资金之前，先下注一定金额的您自己的存款。',
    user_agreement_gen_5_9:
      '5.9. 您接受某些促销可能受到提款限制和/或要求，必须在提款前满足。这些条款将在促销中适当发布并提供。如果您选择在满足适用的下注要求之前提款，我们将在批准任何提款之前扣除整个奖金金额以及与使用奖金金额相关的任何 winnings。',
    user_agreement_gen_6: '6. 在线聊天',
    user_agreement_gen_6_1:
      '6.1. 作为您使用本网站的一部分，我们可能会提供一个在线聊天功能，该功能由我们进行管理并受到控制。我们保留审查聊天记录并保存所有陈述记录的权利。您使用聊天功能应出于休闲和社交目的。',
    user_agreement_gen_6_2: '6.2. 如果您：',
    user_agreement_gen_6_2_a:
      '(a) 发表任何性暗示或极具冒犯性的言论，包括表现出偏见、种族主义、仇恨或亵渎的表达；',
    user_agreement_gen_6_2_b: '(b) 发表攻击性、诽谤性或骚扰性的言论；',
    user_agreement_gen_6_2_c: '(c) 使用聊天功能进行广告、推广或以其他方式与其他在线实体相关；',
    user_agreement_gen_6_2_d:
      '(d) 对 太阳城亚洲 或与本网站相关的任何其他互联网网站发表不真实和/或恶意和/或损害 太阳城亚洲 的言论；',
    user_agreement_gen_6_2_e:
      '(e) 使用聊天功能进行合谋、参与非法活动或鼓励我们认为极其不当的行为。任何可疑聊天都将报告给主管机关。',
    user_agreement_gen_6_3:
      '6.3. 在线聊天作为我们与您之间的交流方式，不应被复制或与任何论坛或第三方共享。',
    user_agreement_gen_7: '7. 责任限制',
    user_agreement_gen_7_1:
      '7.1. 您在本网站上参与游戏的风险自负。网站和游戏均不提供任何形式的保证，无论是明示还是暗示。',
    user_agreement_gen_7_2:
      '7.2. 在不影响前述条款的一般性的前提下，我们、我们的董事、员工、合作伙伴和服务提供商。',
    user_agreement_gen_7_3: '7.3. 不保证软件、游戏和网站适合其目的。',
    user_agreement_gen_7_4: '7.4. 不保证软件、游戏和网站没有错误。',
    user_agreement_gen_7_5: '7.5. 不保证软件、游戏和网站可以无中断访问。',
    user_agreement_gen_7_6:
      '7.6. 对于因您使用网站或参与游戏而引起的任何损失、成本、费用或损害，不论是直接、间接、特殊、后果性、附带性或其他，概不负责。',
    user_agreement_gen_7_7:
      '7.7. 您理解并承认，如果游戏或其互操作性出现故障，在故障期间进行的任何投注均为无效。来自故障游戏的资金将被视为无效，以及任何后续使用该资金进行的游戏轮次，无论使用了什么游戏。',
    user_agreement_gen_7_8:
      '7.8. 您同意全额赔偿我们、我们的董事、员工、合作伙伴和服务提供商，因您使用本网站或参与游戏而可能导致的任何费用、损失、损害、索赔和责任。',
    user_agreement_gen_7_9:
      '7.9. 在法律允许的范围内，因您使用网站而引起的或与之相关的我们的最大责任（无论是基于合同、侵权、违约或其他原因）不超过 100 欧元。',
    user_agreement_gen_8: '8. 违规、处罚和终止',
    user_agreement_gen_8_1:
      '8.1. 如果您违反本用户协议的任何条款，或者我们有合理理由怀疑您违反了这些条款，我们保留不打开、暂停或关闭您的会员账户，或扣留您的奖金支付并将这些资金用于赔偿您应支付的任何损失的权利。',
    user_agreement_gen_8_2:
      '8.2. 您承认，太阳城亚洲 是决定您是否以任何方式违反 太阳城亚洲 的规则、条款或条件的最终决策者，这可能导致您被暂停或永久禁止参与我们网站的决定。',
    user_agreement_gen_9: '9. 自我排除',
    user_agreement_gen_9_1:
      '9.1. 通过请求自我排除的时间，您同意遵守以下条款和条件，这些条款和条件将在客户服务实施您选择的自我排除时间时生效。',
    user_agreement_gen_9_2:
      '9.2. 您可以选择自我排除 1、3、6、12 个月或永久。自我排除请求需通过在线支持提出。',
    user_agreement_gen_9_3: '9.3. 一旦您自我排除，您在此期间将无法访问您的账户或提款。',
    user_agreement_gen_9_4:
      '9.4. 如果您在账户上有待处理的投注时自我排除，所下的投注将保持有效并根据官方结果结算。',
    user_agreement_gen_9_5:
      '9.5. 一旦自我排除期满，您可以提取符合条件的投注的 winnings。太阳城亚洲 不会取消或作废在自我排除生效之前所下的任何投注。',
    user_agreement_gen_9_6: '9.6. 一旦您自我排除，您将无法更改或缩短自我排除的时间。',
    user_agreement_gen_9_7: '9.7. 太阳城亚洲 不对客户自我排除后恢复活动负责。',
    user_agreement_gen_9_8:
      '9.8. 如果在自我排除期内尝试存入款项，我们将保留取消存款并将您账户中的资金退回的权利。',
    user_agreement_gen_9_9: '9.9. 自我排除后，您同意：',
    user_agreement_gen_9_9_a: 'a) 在此期间您不会创建另一个账户。',
    user_agreement_gen_9_9_b: 'b) 在此期间您不会向 太阳城亚洲 账户存款或尝试存款。',
    user_agreement_gen_9_9_c: 'c) 在此期间您不会在此网站进行投注。',
    user_agreement_gen_9_9_d:
      'd) 这是您自愿发起的行为，BlockDance B.V. 对您在自我排除期间可能遭受的任何损失不承担任何责任。',
    user_agreement_pp: '隐私政策',
    user_agreement_pp_1:
      '您在此承认并接受，如果我们认为有必要，我们能够收集并以其他方式使用您的个人数据，以允许您访问和使用网站，并允许您参与游戏。',
    user_agreement_pp_2:
      '我们在此承认，在收集您在上一条款中所述的个人详细信息时，我们受数据保护法的约束。我们将根据最佳商业实践和适用法律保护您的个人信息并尊重您的隐私。',
    user_agreement_pp_3:
      '我们将使用您的个人数据来允许您参与游戏，并进行与您参与游戏相关的操作。我们还可能使用您的个人数据来通知您我们认为您可能感兴趣的变更、新服务和促销活动。如果您不希望收到此类直接营销通信，您可以选择退出此服务。',
    user_agreement_pp_4:
      '除非法律要求，否则您的个人数据不会被披露给第三方。由于 太阳城亚洲 的业务合作伙伴或游戏商或服务提供商可能负责网站整体功能或运营的某些部分，因此可能会向他们披露个人数据。太阳城亚洲 的员工可访问您的个人数据，以执行其职责并为您提供最佳的帮助和服务。您在此同意此类披露。',
    user_agreement_pp_5:
      '我们将保存作为个人数据提供的所有信息。您有权访问我们持有的关于您的个人数据。除非法律要求，否则不会销毁任何数据，或者除非所持有的信息不再需要保留以维持关系的目的。',
    user_agreement_pp_6:
      '为了使您访问网站更加用户友好，跟踪访问网站的情况并改善服务，我们收集一小块由您的浏览器发送的信息，称为 cookie。您可以选择关闭 cookie 的收集。然而，您必须注意，关闭 cookie 可能会严重限制或完全阻碍您使用网站。',
    user_agreement_cp: 'Cookie 政策',
    user_agreement_cp_1: '1. 什么是 cookie？',
    user_agreement_cp_1_ans:
      'Cookie 是一种以非常小的文本文件形式存在的信息，放置在互联网用户的计算机上。它是由网页服务器生成的（基本上就是操作该网站的计算机），并可以在用户访问该网站时由该服务器使用。Cookie 可以被视为互联网用户的身份证，告诉网站用户何时返回。Cookie 不会对您的计算机造成伤害，我们不会在我们的任何 cookie 中存储您任何个人可识别的信息。',
    user_agreement_cp_2: '2. 为什么我们在 太阳城亚洲 上使用 cookie？',
    user_agreement_cp_2_ans:
      '太阳城亚洲 使用两种类型的 cookie：我们设置的 cookie 和第三方设置的 cookie（即其他网站或服务）。太阳城亚洲 的 cookie 使我们能够在您访问期间保持您登录到账户，并根据您的偏好调整网站上显示的信息。',
    user_agreement_cp_3: '3. 我们在 太阳城亚洲 上使用什么 cookie？',
    user_agreement_cp_3_ans_1: '以下是 太阳城亚洲 设置的主要 cookie 列表，以及每个 cookie 的用途：',
    user_agreement_cp_3_ans_2: '_fp - 存储浏览器的指纹。有效期：永久。',
    user_agreement_cp_3_ans_3:
      '_t - 存储用户在当前浏览会话中首次访问网站的时间戳。用于独特访问统计。有效期：浏览会话。',
    user_agreement_cp_3_ans_4:
      '_r - 存储当前浏览会话的 HTTP 引用。用于外部跟踪流量来源。有效期：浏览会话。',
    user_agreement_cp_3_ans_5: '_c - 存储附属活动的标识符。用于附属统计。有效期：永久。',
    user_agreement_cp_3_ans_6: '由第三方为通配符域设置的 cookie：',
    user_agreement_cp_3_ans_7: '*.太阳城亚洲',
    user_agreement_cp_3_ans_8: '谷歌分析：_ga, _gat, _gid',
    user_agreement_cp_3_ans_9: 'Zendesk：__ zlcmid',
    user_agreement_cp_3_ans_10: 'Cloudflare：__ cfuid',
    user_agreement_cp_3_ans_11:
      '请注意，一些浏览器（例如 Mac 上的 Chrome）即使没有打开标签页也会保持后台进程运行，因此这些会话 cookie 可能在会话之间保留。还有一些由第三方脚本为其域设置的 cookie。',
    user_agreement_cp_4: '4. 我如何管理 太阳城亚洲 上的 cookie？',
    user_agreement_cp_4_ans: '如果您希望停止接受 cookie，可以通过浏览器的隐私设置选项进行设置。',
    user_agreement_cp_5: '5. 个人数据保护政策',
    user_agreement_cp_5_ans:
      '太阳城亚洲 的使命是保护您的数据安全，因此我们通过多种方式保护您的数据。我们为客户提供高安全标准，例如对公共网络上流动数据的加密、数据库中数据的加密、审计标准、分布式拒绝服务的缓解，以及现场提供的实时聊天。',
    user_agreement_cp_6: '6. 服务器保护政策',
    user_agreement_cp_6_ans_1: '所有服务器均采用全面加密；',
    user_agreement_cp_6_ans_2: '所有备份均采用加密；',
    user_agreement_cp_6_ans_3: '防火墙、VPN 访问；',
    user_agreement_cp_6_ans_4: '仅通过 VPN 允许访问服务器；',
    user_agreement_cp_6_ans_5: '所有 http/s 服务通过 Cloudflare 运行；',
    user_agreement_cp_6_ans_6: '通过 VPN 连接到节点；',
    user_agreement_cp_6_ans_7: 'SSH 端口转发隧道；',
    user_agreement_cp_6_ans_8: '仅通过 VPN 允许服务；',
    user_agreement_cp_6_ans_9: '服务器有防火墙，仅允许 SSH 端口；',
    user_agreement_cp_6_ans_10: '关键服务的警报。',
    user_agreement_cp_6_ans_11: '数据泄露通知',
    user_agreement_cp_6_ans_12:
      '当 太阳城亚洲 知悉个人数据泄露时，我们将根据 GDPR 的时间框架通知相关用户。',
    user_agreement_cp_7: '7. 数据国际转移',
    user_agreement_cp_7_ans_1:
      '我们仅在必要时向第三方披露个人数据，以提供高质量服务或响应当局的合法请求。',
    user_agreement_cp_7_ans_2:
      '我们向第三方系统共享以下数据：Zendesk Inc. – 如果用户发送消息到实时聊天或向支持邮箱发送电子邮件，则转移用户名和电子邮件信息。',
    user_agreement_cp_7_ans_3:
      '尽管我们尽力而为，但有时可能会出现问题。我们的团队将尽一切可能尽快解决您的问题。为了更快地协助您，您可以通过点击上面的按钮加入 Telegram 群组。',
    user_agreement_cp_7_ans_4: '如果发生错误，请提供以下信息：',
    user_agreement_cp_7_ans_5: '用户名',
    user_agreement_cp_7_ans_6: '问题发生的日期和时间',
    user_agreement_cp_7_ans_7: '游戏 ID 或桌名（如有）',
    user_agreement_cp_7_ans_8: '如果可能，请提供错误的截图',
    user_agreement_cp_7_ans_9:
      '我们非常感谢您提供的帮助和错误报告，因为您的信息报告可以帮助我们改进。',
    user_agreement_cp_7_ans_10: '收集和使用您的个人数据',
    user_agreement_cp_7_ans_11: '收集的数据类型',
    user_agreement_cp_7_ans_12: '个人数据',
    user_agreement_cp_7_ans_13:
      '在使用我们的服务时，我们可能会要求您提供某些可用于联系或识别您的个人可识别信息。个人可识别信息可能包括但不限于：',
    user_agreement_cp_7_ans_14: '电子邮件地址',
    user_agreement_cp_7_ans_15: '名字和姓氏',
    user_agreement_cp_7_ans_16: '使用数据在使用服务时自动收集。',
    user_agreement_cp_7_ans_17:
      '使用数据可能包括您设备的互联网协议地址（例如 IP 地址）、浏览器类型、浏览器版本、您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间、唯一设备标识符和其他诊断数据。',
    user_agreement_cp_7_ans_18:
      '当您通过移动设备访问服务时，我们可能会自动收集某些信息，包括但不限于您使用的移动设备类型、您移动设备的唯一 ID、您移动设备的 IP 地址、您的移动操作系统、您使用的移动互联网浏览器类型、唯一设备标识符和其他诊断数据。',
    user_agreement_cp_7_ans_19:
      '我们还可能收集您每次访问我们的服务时或通过移动设备访问服务时，您的浏览器发送的信息。',
    user_agreement_cp_7_ans_20: '来自第三方社交媒体服务的信息',
    user_agreement_cp_7_ans_21:
      '太阳城亚洲允许您通过以下第三方社交媒体服务创建账户并登录以使用服务：',
    user_agreement_cp_7_ans_22: '谷歌',
    user_agreement_cp_7_ans_23: '脸书',
    user_agreement_cp_7_ans_24: '电报',
    user_agreement_cp_7_ans_25: 'Metamask',
    user_agreement_cp_7_ans_26: 'Web3.0',
    user_agreement_cp_7_ans_27:
      '如果您决定通过或以其他方式授予我们访问第三方社交媒体服务的权限，我们可能会收集已与您第三方社交媒体服务账户相关联的个人数据，例如您的姓名、电子邮件地址、活动或与该账户关联的联系人列表。',
    user_agreement_cp_7_ans_28:
      '您也可以选择通过您第三方社交媒体服务的账户与太阳城亚洲分享更多信息。如果您选择在注册期间或其他方式提供此类信息和个人数据，您就是在授权太阳城亚洲以与本隐私政策一致的方式使用、分享和存储这些信息。',
    user_agreement_delete_personal_data: '删除个人数据',
    user_agreement_dps_ans:
      '如果太阳城亚洲不再有法律理由继续处理或存储您的个人数据，您可以请求删除您的个人数据。请注意，这一权利并不保证——因为如果太阳城亚洲有法律义务存储您的数据，则无法满足您的请求。您可以通过发送电子邮件至support@SunGame请求删除您的个人数据。',
    user_agreement_registration_n_login: '注册和登录',
    user_agreement_rnl_ans_1:
      '您必须年满18岁才能注册。如果您想添加电子邮件地址，请确保您输入的电子邮件地址是正确的，以便以后可用于KYC账户验证。',
    user_agreement_rnl_ans_2:
      '您可以随时登录。为提高安全性，我们建议您添加双重身份验证（2FA）。有关Google身份验证器的更多信息，请查看。',
    user_agreement_rnl_ans_3:
      '如果您需要更改注册的电子邮件，我们非常抱歉，但我们无法更新此信息。如果您坚持要更改您的用户名和/或注册电子邮件，我们建议您关闭当前账户并重新注册。',
  },
  policy: {
    policy_title: '隐私政策',
    policy_term: `<div class="policy_wrapper">
    <div>
      本隐私政策（“政策”）将帮助您了解太阳城亚洲（“我们”）如何使用和保护您在访问和使用[博客网址]（“博客”，“服务”）时提供的数据。
    </div>
    <div>
      我们保留随时更改本政策的权利，并会及时通知您。如果您希望确保您了解最新的更改，建议您经常访问此页面。
    </div>
    <br />
    <div>我们收集的用户数据</div>
    <div>当您访问博客时，我们可能会收集以下数据：</div>
    <ul>
      <li>您的IP地址。</li>
      <li>您的联系信息和电子邮件地址。</li>
      <li>其他信息，例如兴趣和偏好。</li>
      <li>有关您在我们博客上在线行为的数据概况。</li>
    </ul>
    <br />
    <div>我们为何收集您的数据</div>
    <div>
      我们收集您的数据有多个原因：
      <ul>
        <li>更好地了解您的需求。</li>
        <li>改善我们的服务和产品。</li>
        <li>
          向您发送我们认为您会感兴趣的促销电子邮件。
        </li>
        <li>
          联系您填写调查问卷并参与其他类型的市场调研。
        </li>
        <li>
          根据您的在线行为和个人偏好定制我们的博客。
        </li>
      </ul>
    </div>
    <br />
    <div>保护和安全数据</div>
    <div>
      太阳城亚洲致力于保护您的数据并保持其机密性。太阳城亚洲已尽其所能通过实施最新的技术和软件来防止数据被盗、未经授权访问和披露，从而保护我们在线收集的所有信息。
    </div>
    <br />
    <div>我们的Cookie政策</div>
    <div>
      一旦您同意允许我们的博客使用Cookies，您也同意使用它收集的数据，以分析您的在线行为（分析网页流量、您访问的网页以及您在其上花费的时间）。我们通过使用Cookies收集的数据用于根据您的需求定制我们的博客。我们使用这些数据进行统计分析后，这些数据将从我们的系统中完全删除。
    </div>
    <div>
      请注意，Cookies不会以任何方式让我们控制您的计算机。它们仅用于监控您觉得有用的页面和您不喜欢的页面，以便我们能够为您提供更好的体验。
    </div>
    <div>
      如果您想禁用Cookies，可以通过访问互联网浏览器的设置来实现。您可以访问 <a href="https://www.internetcookies.com" target="_blank">https://www.internetcookies.com</a>，该网站包含有关如何在各种浏览器和设备上执行此操作的详细信息。
    </div>
    <br />
    <div>指向其他网站的链接</div>
    <div>
      我们的博客包含指向其他网站的链接。如果您点击这些链接，太阳城亚洲对您的数据和隐私保护不承担责任。访问那些网站不受本隐私政策的约束。确保阅读您从我们网站访问的其他网站的隐私政策文件。
    </div>
    <br />
    <div>限制您个人数据收集的权利</div>
    <div>
      在某些情况下，您可能希望限制您的个人数据的使用和收集。您可以通过以下方式实现这一点：
    </div>
    <div>
      当您在博客上填写表单时，请确保查看是否有可以不勾选的框，如果您不想披露个人信息。
    </div>
    <div>
      如果您已经同意与我们分享您的信息，请随时通过电子邮件与我们联系，我们将非常乐意为您更改此信息。
    </div>
    <div>
      太阳城亚洲不会租赁、出售或分发您的个人信息给任何第三方，除非我们获得您的许可。如果法律要求我们这样做，我们可能会这样做。您的个人信息将在您同意本隐私政策的情况下用于向您发送促销材料。
    </div>
    <br />
    <div>
      专业提示：节省时间，消除法律术语的猜测，使用数千家企业信赖的专业隐私政策生成器。
    </div>
  </div>`,
  },
  term_service: {
    title: '服务条款',
    term: `<div class="term_wrapper">
      <div>
        Meta 构建了使人们能够相互连接、建立社区和发展业务的技术和服务。这些条款管理您对 Facebook、Messenger
        以及我们提供的其他产品、功能、应用、服务、技术和软件（统称为 Meta 产品或产品）的使用，除非我们明确说明适用其他条款（而不是这些条款）。这些产品由
        Meta Platforms, Inc. 提供给您。
      </div>
      <div>
        除非我们另有说明，否则我们不会向您收取使用 Facebook
        或其他受这些条款覆盖的产品和服务的费用。相反，企业、组织和其他个人支付我们向您展示其产品和服务的广告。通过使用我们的产品，您同意我们可以向您展示我们认为可能与您及您的兴趣相关的广告。我们使用您的个人数据来帮助确定向您展示哪些个性化广告。
      </div>
      <div>
        我们不会将您的个人数据出售给广告商，也不会与广告商分享直接识别您（如您的姓名、电子邮件地址或其他联系信息）的信息，除非您给予我们特定的许可。相反，广告商可以告诉我们他们希望看到广告的受众类型，我们将这些广告展示给可能感兴趣的人。我们向广告商提供有关其广告表现的报告，帮助他们了解人们如何与其内容互动。请参见下面的第二节，了解有关这些条款下的个性化广告在
        Meta 产品上如何运作的更多信息。
      </div>
      <div>
        我们的隐私政策解释了我们如何收集和使用您的个人数据，以确定您看到的某些广告，并提供下面描述的所有其他服务。您也可以随时访问相关
        Meta 产品的设置页面，审查您对我们使用您数据的隐私选择。
      </div>
      <br />
      <div>1. 我们提供的服务</div>
      <div>
        我们的使命是赋予人们建立社区的能力，让世界更紧密地联系在一起。为了帮助推进这一使命，我们为您提供以下描述的产品和服务：
      </div>
      <div>
        为您提供个性化体验：
      </div>
      <div>
        您在 Facebook 上的体验与其他任何人都不同：从您在 Facebook 动态消息或我们的视频平台上看到的帖子、故事、事件、广告以及其他内容，到您关注的
        Facebook 页面和您可能使用的其他功能，例如 Facebook 市场和搜索。例如，我们使用您所建立的连接、您选择的选项和设置以及您在我们的产品上分享和执行的内容，来个性化您的体验。
      </div>
      <div>
        连接您与您关心的人和组织：
      </div>
      <div>
        我们帮助您在您使用的 Meta 产品中找到并与对您重要的人、团体、企业、组织等建立联系。
        我们使用数据为您和他人提供建议——例如，推荐加入的群组、参加的活动、关注或发送消息的 Facebook 页面、
        可观看的节目以及您可能希望交朋友的人。更紧密的联系有助于建立更好的社区，
        我们相信，当人们与他们关心的人、团体和组织建立联系时，我们的服务才是最有用的。
      </div>
      <div>
        赋予您表达自我和沟通关于您关心的事情的能力：
      </div>
      <div>
        在 Facebook 上有很多方式可以表达自我，与朋友、家人和其他人沟通您关心的事情——例如，分享状态更新、照片、视频、
        以及在 Meta 产品上分享的故事（与您的设置一致），发送消息或与一个或多个人进行语音或视频通话、
        创建活动或群组，或者向您的个人资料添加内容，以及向您展示其他人如何与您的内容互动的洞察。
        我们还开发了并继续探索新技术，例如增强现实和 360 视频，以便人们在 Meta 产品上创建和分享更具表现力和吸引力的内容。
      </div>
      <div>
        帮助您发现可能感兴趣的内容、产品和服务：
      </div>
      <div>
        我们向您展示个性化广告、优惠和其他赞助或商业内容，以帮助您发现 Facebook 和其他 Meta 产品上许多企业和组织提供的内容、产品和服务。
        第 2 节将对此进行更详细的解释。
      </div>
      <div>
        促进我们的服务的安全性、保障性和完整性，打击有害行为，确保我们的用户社区安全：
      </div>
      <div>
        人们只有在感到安全和有保障的情况下才能在 Meta 产品上建立社区。我们努力维护我们产品和服务的安全性（包括可用性、真实性、完整性和保密性）。
        我们在全球范围内雇佣专门团队，与外部服务提供商、合作伙伴和其他相关实体合作，并开发先进的技术系统，以检测对我们产品的潜在滥用、
        对他人的有害行为，以及我们可能能够支持或保护我们的社区的情况，包括响应用户报告的可能违反内容。如果我们得知此类内容或行为，
        我们可能会根据我们的评估采取适当的行动，包括通知您、提供帮助、删除内容、移除或限制某些功能的访问、禁用帐户或联系执法部门。
        当我们检测到某人使用我们的产品的滥用或有害行为时，我们会在 Meta 公司之间共享数据，或为了帮助保持 Meta 产品、
        用户和社区的安全。例如，我们与提供金融产品和服务的 Meta 公司共享信息，以帮助他们促进安全、
        保障和完整性并遵守适用法律。Meta 可能会访问、保存、
        使用和共享其收集的有关您的任何信息，在其有善意相信法律要求或允许这样做的情况下。有关更多信息，请查看我们的隐私政策。
      </div>
      <div>
        在某些情况下，监督委员会可能会审查我们的决定，受其条款和章程的约束。在这里了解更多信息。
      </div>
      <div>
        使用和开发先进技术为每个人提供安全和功能性服务：
      </div>
      <div>
        我们使用和开发先进的技术——例如人工智能、机器学习系统和增强现实——
        以便人们能够安全使用我们的产品，无论其身体能力或地理位置如何。
        例如，这种技术帮助有视觉障碍的人理解在 Facebook 或 Instagram 上共享的照片或视频中有什么或谁。
        我们还构建了复杂的网络和通信技术，以帮助更多的人在有限的接入地区连接到互联网。
        我们还开发自动化系统，以提高我们检测和移除可能对我们的社区和产品完整性造成伤害的滥用和危险活动的能力。
      </div>
      <div>
        研究改善我们服务的方法：
      </div>
      <div>
        我们从事研究，以开发、测试和改善我们的产品。这包括分析我们关于用户的数据，了解人们如何使用我们的产品，
        例如，通过进行调查、测试和排除新功能的问题。
        我们的隐私政策解释了我们如何使用数据来支持这一研究，以便开发和改善我们的服务。
      </div>
      <div>
        在 Meta 公司产品之间提供一致和无缝的体验：
      </div>
      <div>
        我们的产品帮助您找到并与对您重要的人、团体、企业、组织等建立联系。
        我们设计我们的系统，以确保您在使用的不同 Meta 公司产品之间的体验一致且无缝。
        例如，我们使用您在 Facebook 上与之互动的人的数据，以便更轻松地让您在 Instagram 或 Messenger 上与他们建立联系，
        并使您能够通过 Messenger 与您在 Facebook 上关注的企业进行沟通。
      </div>
      <div>
        确保您能够访问我们的服务：
      </div>
      <div>
        为了运营我们的全球服务并使您能够与全球各地的人们建立联系，我们需要将内容和数据传输、存储和分发到我们的数据中心、合作伙伴、服务提供商、游戏商和全球各地的系统，
        包括您居住国以外的地区。使用这一全球基础设施是提供我们服务所必需和至关重要的。
        这一基础设施可能由 Meta Platforms, Inc.、Meta Platforms Ireland Limited 或其附属公司拥有、运营或控制。
      </div>
      <br />
      <div>
        2. 我们的服务如何获得资金
      </div>
      <div>
        在使用 Meta 公司产品的过程中，您同意我们可以向您展示个性化广告和其他商业和赞助内容，而不是通过支付使用 Facebook
        和我们提供的其他产品和服务。
        通过这些条款所涵盖的 Meta 产品，您同意我们可以向您展示企业和组织支付给我们的广告费用来推广的个性化广告和其他商业和赞助内容。
        我们使用您的个人数据，例如有关您的活动和兴趣的信息，以向您展示可能与您更相关的个性化广告和赞助内容。
      </div>
      <div>
        保护人们隐私是我们设计个性化广告系统的核心。
        这意味着我们可以向您展示相关和有用的广告，而无需告诉广告商您是谁。
        我们不会出售您的个人数据。我们允许广告商告诉我们他们的商业目标，
        以及他们希望看到广告的受众（例如，18-35 岁、喜欢骑自行车的人）。
        然后，我们将他们的广告展示给我们认为可能感兴趣的人。
      </div>
      <div>
        我们还向广告商提供有关其广告表现的报告，以帮助他们了解人们如何与其内容在 Meta 产品上和下进行互动。
        例如，我们向广告商提供一般的人口统计和兴趣信息，以帮助他们更好地了解其受众，
        例如，居住在马德里并且喜欢软件工程的25至34岁的女性已经看到了广告。
        除非您明确允许我们，否则我们不会分享可以直接识别您的信息（例如，您的姓名或电子邮件地址，这些信息本身可以用于联系您或识别您是谁）。
        在这里了解更多关于 Meta 广告的运作方式。
      </div>
      <div>
        我们收集和使用您的个人数据，以向您提供上述服务。您可以在我们的隐私政策中了解我们如何收集和使用您的数据。
        您对您看到的广告和广告商的类型以及我们用于确定向您展示哪些广告的信息类型拥有控制权。了解更多信息。
      </div>
      <br />
      <div>
        3. 您对 Facebook 和我们的社区的承诺
      </div>
      <div>
        我们向您和其他人提供这些服务，以帮助推进我们的使命。作为交换，我们需要您作出以下承诺：
      </div>
      <div>
        3.1. 谁可以使用 Facebook
      </div>
      <div>
        当人们为他们的观点和行为负责时，我们的社区会更安全，更有责任感。为此，您必须：
      </div>
      <div>
        提供与您日常生活中使用的姓名相同的姓名以供您的帐户使用。
      </div>
      <div>
        提供关于您自己的准确信息。
      </div>
      <div>
        仅创建一个帐户（您自己的），并将其用于个人目的。
      </div>
      <div>
        不共享您的密码，不给其他人访问您的 Facebook 帐户的权限，或将您的帐户转让给任何人（未经我们许可）。
      </div>
      <div>
        我们努力使 Facebook 广泛可用，但如果您：
      </div>
      <div>
        不满 13 岁。
      </div>
      <div>
        是被定罪的性犯罪者。
      </div>
      <div>
        我们曾因违反我们的条款或社区标准，或适用于您使用 Facebook
        的其他条款和政策而禁用您的帐户。如果我们因违反我们的条款、社区标准或其他条款和政策而禁用您的帐户，您同意未经我们的许可不会重新创建帐户。
      </div>
      <br />
      <div>
        3.2. 您可以在Meta产品上分享和执行哪些操作
      </div>
      <div>
        我们希望人们使用Meta产品来表达自己并分享对他们来说重要的内容，但不能以牺牲他人的安全和福祉或我们社区的诚信为代价。
        因此，您同意不从事下述行为（或协助或支持他人这样做）：
      </div>
      <div>
        您不得使用我们的产品执行或分享任何内容：
      </div>
      <div>
        违反这些条款、社区标准或适用于您使用我们产品的其他条款和政策。
      </div>
      <div>
        违法、误导性、歧视性或欺诈性（或协助他人以这种方式使用我们的产品）。
      </div>
      <div>
        您不拥有或没有必要的权利进行分享。
      </div>
      <div>
        侵犯或违反他人权利，包括他们的知识产权（例如，侵犯他人的版权或商标，或分发或销售假冒或盗版商品），除非适用法律适用例外或限制。
      </div>
      <div>
        您不得上传病毒或恶意代码，使用服务发送垃圾邮件，或做任何其他可能禁用、超负荷、干扰或损害我们服务、系统或产品的正常工作、完整性、操作或外观的事情。
      </div>
      <div>
        您不得未经我们事先许可，使用自动化手段访问或收集我们产品的数据，或试图访问您没有权限访问的数据。
      </div>
      <div>
        您不得代理、请求或收集产品用户名或密码，或不当占用访问令牌。
      </div>
      <div>
        除非平台条款另有规定，您不得出售、许可或购买任何从我们或我们的服务获得的数据。
      </div>
      <div>
        您不得滥用任何报告、标记、争议或上诉渠道，例如通过制作欺诈性、重复或无根据的报告或上诉。
      </div>
      <div>
        我们可以删除或限制访问违反这些条款的内容。我们还可以因违反这些条款的行为暂停或禁用您的账户，如第4.B节所述。
      </div>
      <div>
        如果我们删除您共享的违反社区标准的内容，我们会通知您并解释您可以请求另一次审核的选项，除非您严重或反复违反这些条款，或者这样做可能使我们或他人面临法律责任；损害我们的用户社区；损害或干扰我们任何服务、系统或产品的完整性或操作；由于技术限制而受到限制；或出于法律原因被禁止这样做。有关账户暂停或终止的信息，请参见下面的第4.B节。
      </div>
      <div>
        为了支持我们的社区，我们鼓励您举报您认为侵犯您权利（包括知识产权）或我们条款和政策的内容或行为，如果您所在的司法管辖区有此功能。
      </div>
      <div>
        如果我们认为这样做是合理必要的，以避免或减轻对我们服务的滥用或对Meta的不利法律或监管影响，我们也可以删除或限制访问内容功能、服务或信息。
      </div>
      <div>
        3.3. 您给予我们的权限
      </div>
      <div>
        我们需要您授予某些权限，以提供我们的服务：
      </div>
      <div>
        使用您创建和分享的内容的权限：您共享或上传的某些内容，例如照片或视频，可能受到知识产权法的保护。
      </div>
      <div>
        您保留在Facebook和您使用的其他Meta公司产品上创建和分享的任何此类内容的知识产权（如版权或商标）所有权。这些条款中的内容不会剥夺您对自己内容的权利。您可以自由地与他人分享您的内容，随心所欲。
      </div>
      <div>
        然而，为了提供我们的服务，我们需要您授予我们一些法律权限（称为“许可”）以使用这些内容。这仅用于提供和改进我们上述第1节中描述的产品和服务。
      </div>
      <div>
        具体来说，当您在我们的产品上或与我们的产品相关联时共享、发布或上传受知识产权保护的内容时，您授予我们一个非独占、可转让、可再授权、免版税的全球许可，以托管、使用、分发、修改、运行、复制、公开表演或展示、翻译和创建您内容的衍生作品（与您的隐私和应用设置一致）。这意味着，例如，如果您在Facebook上分享一张照片，您允许我们存储、复制和与他人分享它（同样，与您的设置一致），例如Meta产品或支持这些产品和服务的服务提供商。此许可将在您的内容从我们的系统中删除时结束。
      </div>
      <div>
        您可以随时删除您共享、发布和上传的单个内容。此外，如果您删除您的账户，所有发布到您个人账户的内容将被删除。了解更多有关如何删除您的账户的信息。账户删除不会自动删除您作为页面管理员发布的内容或与其他用户共同创建的内容，例如共享相册中的照片，这些内容可能仍然对其他相册成员可见。
      </div>
      <div>
        在我们开始账户删除过程或收到内容删除请求后，删除内容可能需要最多90天的时间。如果您将内容发送到垃圾箱，删除过程将在30天内自动开始，除非您选择更早删除内容。在进行此类内容的删除过程中，该内容对其他用户将不再可见。内容删除后，可能需要我们最多90天的时间才能从备份和灾难恢复系统中删除它。
      </div>
      <div>
        在以下情况下，从账户删除或内容删除过程开始后的90天内，内容将不会被删除：
        1. 您的内容已被他人根据此许可使用，并且他们没有删除它（在这种情况下，此许可将继续适用直到该内容被删除）；
        2. 由于我们的系统的技术限制，无法在90天内删除内容，在这种情况下，我们将尽快完成删除；
        3. 立即删除将限制我们进行以下操作的能力：
      </div>
      <div>
        调查或识别非法活动或违反我们条款和政策的行为（例如，识别或调查对我们产品或系统的滥用）；
      </div>
      <div>
        保护我们的产品、系统、服务、员工和用户的安全、完整性和安全性，并为自己辩护；遵守保存证据的法律义务，包括Meta公司提供金融产品和服务时需要遵守的记录保存义务；或遵循司法或行政当局、执法或政府机构的请求；
      </div>
      <div>
        在这种情况下，内容的保留时间不会超过为了保留这些内容而必要的时间（确切的时间将因具体情况而异）。
        在上述每种情况下，此许可将继续有效，直到内容被完全删除。
        使用您的姓名、个人资料照片以及您与广告和赞助或商业内容的互动信息的许可：您授权我们使用您的姓名和个人资料照片以及您在Facebook上所采取的与广告、优惠及其他赞助或商业内容相关的行动信息，而无需向您支付任何报酬。例如，我们可能会向您的朋友显示您对某个广告活动感兴趣或已点赞由品牌创建的Facebook页面，而该品牌已付费给我们在Facebook上展示其广告。此类广告和内容仅可由拥有您许可的人查看您在Meta产品上所采取的行动。您可以了解更多关于您的广告设置和偏好的信息。
        使用您下载或使用的软件的更新许可：如果您下载或使用我们的软件，您授权我们下载并安装可用的更新软件。
      </div>
      <div>
        3.4. 使用我们的知识产权的限制
      </div>
      <div>
        如果您使用我们拥有并在我们的产品中提供的受知识产权保护的内容（例如，您添加到自己在Facebook上创建或共享的内容中的图像、设计、视频或声音），我们保留对该内容的所有权利（但不包括您的权利）。
        您只能根据我们的品牌使用指南或事先获得我们书面许可的明确许可来使用我们的版权或商标（或任何类似标记）。您必须获得我们的书面许可（或根据开源许可证的许可）才能修改、翻译、创建衍生作品、反编译或逆向工程我们的产品或其组件，或以其他方式尝试从我们这里提取源代码，除非适用法律适用例外或限制，或者您的行为与Meta漏洞奖励计划相关。
      </div>
      <br />
      <div>
        4. 附加条款
      </div>
      <div>
        4.1. 更新我们的条款
      </div>
      <div>
        我们不断努力改进我们的服务，并开发新功能，以使我们的产品对您和我们的社区更好。因此，我们可能需要不时更新这些条款，以准确反映我们的服务和做法，以促进在我们的产品和服务上提供安全可靠的体验，并/或遵守适用法律。我们只会在条款不再适用或不完整的情况下进行任何更改，并且仅在更改合理并充分考虑您的利益，或更改出于安全和保护目的或遵守适用法律而需要的情况下。
      </div>
      <div>
        我们会在更改这些条款之前至少提前30天通知您（例如，通过电子邮件或通过我们的产品），并给您机会在更改生效之前查看它们，除非法律要求进行更改。一旦任何更新的条款生效，如果您继续使用我们的产品，您将受其约束。
        我们希望您继续使用我们的产品，但如果您不同意我们的更新条款并且不再希望成为Meta社区的一部分，您可以随时删除您的账户。
      </div>
      <div>
        4.2. 账户暂停或终止
      </div>
      <div>
        我们希望Facebook成为一个人们感到受欢迎和安全的地方，以表达自己并分享他们的想法和观点。
        如果我们自行判断您明显、严重或重复违反我们的条款或政策，包括特别是社区标准，我们可能会暂停或永久禁用您对Meta公司产品的访问，
        并且我们可能会永久禁用或删除您的账户。如果您多次侵犯他人的知识产权，或者我们出于法律原因被要求这样做，我们也可能会禁用或删除您的账户。
      </div>
      <div>
        如果在注册后您的账户未得到确认，您的账户未使用并在较长一段时间内保持不活动，或者我们检测到有人可能在未经您许可的情况下使用了它而且无法确认您对账户的所有权，我们可能会禁用或删除您的账户。了解有关我们如何禁用和删除账户的更多信息。
      </div>
      <div>
        当我们采取这样的行动时，我们会通知您并解释您有何选择请求审查，除非这样做可能会使我们或他人承担法律责任；危害我们用户社区；妨碍或干扰我们任何服务、系统或产品的完整性或运行；或由于技术限制而受到限制；或出于法律原因被禁止。
      </div>
      <div>
        如果您的账户被禁用，您可以了解更多关于您可以做什么以及如果您认为我们错误地禁用了您的账户如何与我们联系的信息。
      </div>
      <div>
        如果您删除或我们禁用或删除您的账户，这些条款将终止作为您和我们之间的协议，但以下条款将继续有效：3，4.2-4.5。
      </div>
      <div>
        4.3. 责任限制
      </div>
      <div>
        我们努力提供尽可能最好的产品，并为所有使用它们的人指定明确的指导方针。然而，我们的产品是按“原样”提供的，
        在法律允许的范围内，我们不保证它们始终是安全的、可靠的或没有错误的，或者它们不会发生中断、延迟或缺陷。在法律允许的范围内，我们也声明所有的担保，无论是明示还是暗示，包括对适销性、特定用途适用性、所有权和非侵权的暗示担保。
        我们无法控制或指导他人所做或所说的事情，我们对他们的行为或行为（无论在线还是离线）或他们分享的任何内容（包括冒犯性、不当、淫秽、非法和其他令人反感的内容）不承担责任。
      </div>
      <div>
        我们无法预测我们的产品可能出现的问题。因此，在适用法律允许的最大范围内，我们的责任将限制在法律允许的最大范围内。
        在法律允许的最大范围内，在任何情况下，我们都不对因这些条款或Meta产品而产生或与之相关的任何利润、收入、信息或数据的丧失，
        或因任何损害（无论因何原因及基于任何责任理论，包括过失）而导致的后果性、特殊、间接、示范性、惩罚性或偶发性损害负责，即使我们已被告知可能会发生此类损害。
      </div>
      <div>
        4.4. 争议
      </div>
      <div>
        我们努力提供明确的规则，以便能够限制或希望避免您和我们之间的争议。然而，如果确实出现争议，
        事先知道它可以在哪里解决以及适用哪些法律是很有用的。
        如果您是消费者，您居住国的法律将适用于您对我们提出的任何索赔、诉因或争议，该争议源于或与这些条款或Meta产品相关，
        您可以在该国任何有管辖权的法院解决您的索赔。
        在所有其他情况下，以及Meta对您提起的任何索赔、诉因或争议中，您和Meta同意任何此类索赔、诉因或争议必须在加利福尼亚北区美国地方法院或位于圣马特奥县的州法院独占解决。
        您还同意，您接受这两家法院的个人管辖权，以便为诉讼任何此类索赔，且加利福尼亚州的法律将管辖这些条款及任何索赔、诉因或争议，而不考虑法律冲突条款。
        在不妨碍上述规定的情况下，您同意，Meta可以自行决定在您居住的国家的任何有管辖权的法院提起对您任何的索赔、诉因或争议。
      </div>
      <div>
        4.5. 其他
      </div>
      <div>
        这些条款（以前称为权利与责任声明）构成您与Meta Platforms, Inc.之间关于您使用我们产品的完整协议。
        它们取代任何先前的协议。
      </div>
      <div>
        我们提供的一些产品也受补充条款的管辖。如果您使用任何这些产品，将提供补充条款，并将成为我们与您的协议的一部分。
        例如，如果您出于商业目的访问或使用我们的产品，例如购买广告、销售产品、开发应用程序、为您的业务管理一个小组或页面，
        或使用我们的测量服务，您必须同意我们的商业条款。如果您发布或分享包含音乐的内容，您必须遵守我们的音乐指南。
        如果任何补充条款与这些条款发生冲突，补充条款应在冲突的范围内优先适用。
      </div>
      <div>
        如果这些条款的任何部分被认为不可执行，则该不可执行部分将被视为修订到必要的最低限度，以使其可执行。如果无法使其可执行，则将其剥离，剩余部分将继续有效。如果我们未能执行这些条款，则不应视为放弃。对这些条款的任何修订或放弃必须以书面形式作出并由我们签署。
      </div>
      <div>
        您不得在未获得我们同意的情况下，将您在这些条款下的任何权利或义务转让给其他人。
      </div>
      <div>
        如果您的账户被纪念，您可以指定一个人（称为遗产联系人）来管理您的账户。如果您在设置中启用此功能，只有您的遗产联系人或您在有效遗嘱或类似法律文件中指定的、在您去世或失去能力后明确同意披露您的内容的人，才能在您的账户被纪念后寻求有限的信息披露。
      </div>
      <div>
        这些条款不赋予任何第三方受益人权利。我们在这些条款下的所有权利和义务可以在与合并、收购或资产出售相关的情况下，或根据法律或其他方式自由转让。
      </div>
      <div>
        在某些情况下，我们可能会更改您账户的用户名（例如，如果其他人声称该用户名且它与您日常生活中使用的姓名无关）。
      </div>
      <div>
        我们始终欢迎您对我们的产品和服务的反馈和其他建议。然而，我们可以在没有任何限制或补偿义务的情况下使用反馈和建议，并且我们没有义务保密。我们保留所有未明确授予您的权利。
      </div>
      <br />
      <div>
        5. 可能适用于您的其他条款和政策
      </div>
      <div>
        <strong>社区标准：</strong> 这些指导方针概述了我们对您在Facebook上发布的内容及您在Facebook和其他Meta产品上的活动的标准。
      </div>
      <div>
        <strong>商业条款：</strong> 如果您出于任何商业目的访问或使用我们的产品，包括广告、在我们的平台上运营应用程序、使用我们的测量服务、管理商业群组或页面，或销售商品或服务，则这些条款适用。
      </div>
      <div>
        <strong>商业政策：</strong> 这些指导方针概述了在Facebook、Instagram和WhatsApp上提供产品或服务出售时适用的政策。
      </div>
      <div>
        <strong>音乐指南：</strong> 如果您在任何Meta产品上发布或分享包含音乐的内容，这些指南概述了适用的政策。
        <strong>广告政策：</strong> 这些政策适用于在Meta产品上投放广告的合作伙伴，并指定了合作伙伴在Meta产品上投放广告时允许的广告内容类型。
      </div>
      <div>
        <strong>自助广告条款：</strong> 当您使用自助广告接口创建、提交或交付广告或其他商业或赞助活动或内容时，这些条款适用。
      </div>
      <div>
        <strong>Facebook页面、群组和活动政策：</strong> 如果您创建或管理Facebook页面、群组或活动，或者如果您使用Facebook进行推广的沟通或管理，这些指导方针适用。
      </div>
      <div>
        <strong>Meta平台政策：</strong> 这些条款适用于使用一组API、SDK、工具、插件、代码、技术、内容和服务的条款，允许其他人开发功能、从Meta产品中检索数据或向我们提供数据。
      </div>
      <div>
        <strong>开发者支付条款：</strong> 这些条款适用于使用Facebook支付的应用程序开发者。
      </div>
      <div>
        <strong>Meta品牌资源：</strong> 这些指导方针概述了使用Meta商标、标志和截图时适用的政策。
      </div>
      <div>
        <strong>推荐指南：</strong> Facebook推荐指南和Instagram推荐指南概述了我们对推荐和不推荐内容的标准。
      </div>
      <div>
        <strong>直播政策：</strong> 这些政策适用于所有广播到Facebook Live的内容。
      </div>`,
  },
  datadeletion: {
    content: `<div class="dd_wrapper">
    <div>
  太阳城亚洲 只是一个基于网络的应用程序，我们不会在我们的服务器上保存您的个人数据。根据Facebook平台规则，我们必须提供用户数据删除回调URL或数据删除说明URL。如果您想删除在FinMonster上的活动，请按照以下说明操作：
    </div>
  <br />
  <div>
  1.  转到您的Facebook帐户的设置与隐私。点击“设置”。
  </div>
  <br />
   <div>
  2.  然后，转到“应用和网站”，您将看到您所有应用的活动。
  </div>
  <br />
  <div>
  3.  选择太阳城亚洲 Production的选项框。
  </div>
  <br />
  <div>
  4.  点击“删除”按钮。
  </div>`,
  },
  deposit: {
    select_payment: '选择付款方式',
    make_deposit: '进行存款',
    make_deposit_title: '请选择或输入存款金额',
    enter_amount: '输入金额',
    min_deposit: '最低存款',
    max_deposit: '最高存款',
    deposit_btn: '存款',
    all: '全部',
    all_vault: '全额',
    all_bonus: '所有红利',
    use_now: '立即使用',
    select_bonus: '选择红利',
    claim_bonus: '立即领取您的红利！',
    label: '存款 - 领取您的红利！',
    select_payment_method: '请选择您的付款方式',
    valid_amount: '请填写有效的存款金额',
    out_range: '存款金额超出交易范围',
    unavailable_bonus: '您不符合领取此红利的条件，请查看相关要求',
    wager_requirement: '投注要求',
    applied_text: '已选用',
    bonus_limit: '红利上限',
    voucher_left: '剩余红利券',
    unavailable_text: '不可用',
    fast_text: '快速',
    usdt_deposit_label: 'USDT 存款',
    copy_usdt_wallet_addr: '复制USDT钱包地址',
    deposit_inform_cs: '转完帐请截图后通知客服',
    deposit_amt_label: '存款金额',
    deposit_rates: '汇率:',
    deposit_usdt_amt_label: 'USDT金额',
  },
  transaction: {
    tran_record: '交易记录',
    deposit: '存款',
    withdrawal: '取款',
    betting: '投注',
    records: '记录',
    others: '其他',
    bonus: '红利',
    adjustment: '调整',
    pending: '待处理',
    approved: '已批准',
    rejected: '已拒绝',
    add: '增加',
    deduct: '扣除',
    freechip_bonus: '免费筹码红利',
    reload_bonus: '充值红利',
    member_bonus: '会员红利',
    bonus_rebate: '红利返利',
    bonus_expired: '红利已过期',
    to: '到',
    total: '总计：',
    total_bet: '总投注：',
    total_winloss: '总赢/亏：',
    bet: '投注',
    winLoss: '赢/亏',
    win_status: '赢',
    draw_status: '平局',
    lose_status: '输',
    bet_status: '投注',
    cancel_status: '已取消',
    void_status: '无效',
    status_pending: '处理中',
    status_success: '成功',
    status_failed: '已拒绝',
    bonus_status1: '进行中',
    bonus_status2: '已完成',
    bonus_status3: '已过期',
    used: '已使用',
  },
  profile: {
    alertTitle1: '完善个人资料',
    alertContent1: '请完善您的个人信息。',
    title1: '个人资料',
    username: '用户名',
    dob: '出生日期',
    email: '电子邮件',
    verify_btn: '验证',
    set_dob_btn: '设置出生日期',
    your_dob_is: '您的出生日期是',
    confirm_btn: '确认',
    cancel_btn: '取消',
    confirmation: '确认',
    edit_avatar: '编辑头像',
    modify_profile: '修改个人资料',
    nickname: '昵称',
    nickname_validate: '请避免使用特殊字符，否则您的账户可能会被锁定。',
    change_ps: '更改密码',
    old_ps: '旧密码',
    new_ps: '新密码',
    confirm_ps: '重新输入密码',
    modify_btn: '修改',
    update_success: '更新成功。',
    remove_success: '删除成功。',
    remove_failed: '删除失败。',
    email_verify: '电子邮件验证',
    verify_email_label: '验证电子邮件',
    verify_phone_label: '验证电话号码',
    email_text: '我们已经向您的电子邮件地址发送了一封包含验证链接的邮件，请您查收并完成验证。',
    confirm_birthday: '确认生日',
    birth_text: '您的生日已成功更新。',
    set_dob: '请设置您的出生日期',
    invalid_email: '此电子邮件地址无效。请提供一个新的电子邮件地址并重试',
    empty_email: '请输入有效的电子邮件地址。',
    invalid_phone: '此电话号码无效。请提供一个新的电话号码并重试',
    empty_phone: '请输入有效的电话号码。',
    empty_veri_code: '请输入验证码。',
    invalid_veri_code: '请确保您的电话号码已完成验证。',
    verify_label: '验证',
    email_addrss: '电子邮件地址',
    member_center: '会员中心',
    personal_detail: '个人信息',
    change_pass: '更改密码',
    funding: '资金',
    change_pass_title1: '您的登录密码将用于提款操作，请确保密码具有足够的强度。',
    update_data: '更新数据',
    verify_title1: '请完成账户验证，以解锁更多功能。',
    valid_nickname: '请输入最多20个字母和数字字符',
    funding_add_card: '添加银行卡',
    funding_title1: '每个玩家可以绑定一张银行卡，若需要更换请联系客服。',
    bank_details: '银行详情',
    bank_notify_user: '请提供完整且准确的银行信息。',
    usdt_notify_user: '请提供完整且准确的USDT信息。',
    ifsc_code: 'IFSC代码',
    bank_num: '银行号码',
    login_pass: '登录密码',
    alert: '警告',
    add_bank_success: '银行账户添加成功',
    ifsc_valid: '无效的IFSC。IFSC格式：ABCD0123456',
    delete_bank_success: '银行账户已删除',
    acc_name: '账户名称',
    acc_no_valid: '无效的账户号码：请只填写数字。',
    delete_bank:
      '您确定要移除您的账户号码吗？一旦移除，该账户号码将无法再次使用，除非您联系客户服务寻求进一步帮助。',
    delete_phone:
      '您确定要移除您的电话号码吗？一旦移除，该电话号码将无法再次使用，除非您联系客户服务寻求进一步帮助。',
    delete_email:
      '您确定要移除您的电子邮件地址吗？一旦移除，该电子邮件地址将无法再次使用，除非您联系客户服务寻求进一步帮助。',
    delete_verify_password: '请输入您的密码以确保您是账户持有者',
    acc_name_valid: '无效的账户名称：请只填写字母字符。',
    add: '添加',
    blackListPhone: '此电话号码已被限制。请联系客户服务获取更多信息。',
    blackListEmail: '此电子邮件已被限制。请联系客户服务获取更多信息。',
    blackListBankAccount: '此账户号码已被限制。请联系客户服务获取更多信息。',
    usdt_title: '资金添加USDT钱包',
    usdt_content1: ' USDT(TRC)地址格式。地址由字母T开头的34个字符组成。',
    usdt_content2: '请确保信息填写正确，平台对此不承担任何赔偿责任。',
    usdt_wallet_addr: '钱包地址(TRC20)',
    usdt_details: 'USDT 详情',
    binding_usdt_details: 'USDT钱包地址',
  },
  slots: {
    search_placeholder: '请输入游戏名称或游戏平台名称',
    all: '全部',
    all_provider: '游戏平台',
    no_provider: '没有游戏平台',
    provider_not_found: '未找到游戏平台',
    provider: '游戏平台',
    clear_all: '清除全部',
    recommend_head: '为您推荐',
    search_head: '搜索结果',
    nomore_game: '没有更多游戏',
    more_game: '更多游戏',
    search_provider_placeholder: '请输入游戏平台名称',
  },
  withdrawal: {
    verify: '验证',
    verify_email_phonme_warning: '在进行提款之前，您需要验证您的手机号码和电子邮件地址。',
    verify_email_warning: '在进行提款之前，您需要验证您的电子邮件地址。',
    verify_phone_warning: '在进行提款之前，您需要验证您的手机号码。',
    withdraw: '提款',
    funding: '资金',
    funding_warning: '在提款之前，您必须至少绑定一个银行账户。',
    bind_now: '立即绑定',
    title: '提款',
    choose_method: '选择提款方式',
    information: '提款信息',
    choose_bank: '选择提款银行卡',
    ifsc_code: 'IFSC代码',
    card_number: '卡号',
    holder_name: '持卡人姓名',
    account_number: '账户号码',
    account_name: '账户名称',
    invalid_upi_account: '无效的UPI账户。UPI格式错误。',
    invalid_account_name: '无效的账户名称：不允许输入数字。',
    account_name_required: '账户名称必填',
    wager_requirement: '投注要求',
    meet_wager_requirement: '您必须满足投注要求才能进行提款',
    current_bet_amount: '当前投注金额',
    withdraw_amount: '提款金额',
    valid_amount: '请输入有效的提款金额',
    out_range: '提款金额超出交易范围',
    min_withdrawal: '最低提款金额',
    max_withdrawal: '最高提款金额',
    text1:
      '亲爱的玩家！为防止欺诈，您需要将存款金额至少投注一次，投注比例为1.01或更高。您的投注金额将成为您可提款的金额。完成存款投注后，您可以提取任意金额。',
    text2: '点击“提款”后，处理您的提款请求可能需要最长24小时，请耐心等待。',
    wager_content1: '您每天最多可以申请',
    wager_content2: '次提款,',
    wager_content3: '次提款可用！',
    converted_amount: '转换后的提款金额',
    add_wallet_title: '添加钱包',
    usdt_wallet_addr: 'USDT钱包地址',
  },
  affiliate: {
    title: '代理',
    overview: '概览',
    retention_program: '留存计划',
    referred_users: '推荐用户',
    commission: '佣金',
    overview_text1:
      '推荐我们的品牌并加入太阳城亚洲合作伙伴计划，赚取收入！每个通过您的推荐链接在太阳城亚洲注册并进行游戏的用户，都将为您带来佣金。',
    overview_text2:
      '成为太阳城亚洲的合作伙伴意味着您将在我们赌场的所有投注中赚取佣金——与传统的合作伙伴计划不同，在这个计划中，无论用户赢或输，您都将获得相同的佣金。',
    invite_code_link: '邀请码链接',
    program_text1:
      '在太阳城亚洲，我们始终将玩家的满意度放在首位。这是我们用户与平台互动的长期战略核心，旨在为玩家和合作伙伴提供最佳体验。通过独特的计划和活动，我们奖励玩家，增强他们的满意度。这些计划不仅为玩家提供额外的资金和激励，还改善了投注体验，帮助您增加整体佣金收入。',
    program_text2: '这些计划包括：',
    rakeback: '返佣',
    weekly_bonus: '每周奖金',
    monthly_bonus: '每月奖金',
    daily_reload: '每日充值',
    vip_rewards: 'VIP级别奖励',
    program_text3:
      '通过这种独特的方法，一旦您了解这些成本，您的佣金将基于房屋边际。根据玩家获利的限制，您所获得的房屋佣金将比我们在任何游戏中宣传的标准房屋佣金低约45%。',
    program_text4:
      '如果您希望了解更多关于这些成本如何计算的详细信息，请随时联系您的合作伙伴经理，我们将为您提供支持。',
    program_text5:
      '留存计划适用于您在体育和赌场两方面的合作伙伴。然而，在计算体育博彩投注的合作佣金之前，相关成本将被先行扣除，以便我们能够设定3%的预期利润标准费率。',
    register: '注册：',
    first_deposit_time: '首次存款时间：',
    first_deposit_amount: '首次存款金额：',
    deposit: '存款：',
    total_bet_amount: '总投注金额：',
    total_winloss: '总赢/亏：',
    ggr: 'GGR：',
    ngr: 'NGR：',
    tooltips_content: '结果可能会延迟 5 到 10 分钟。',
  },
  affiliateNew: {
    details: '详情',
    Register: '注册',
    total: '总计',
    Deposit: '存款',
    Sports: '体育赛事',
    Casino: '真人视讯',
    Slot: '老虎机',
    Poker: '棋牌游戏',
    Original: '原创游戏',
    commission: '佣金',
    GGR: '总毛收入 (GGR)',
    NGR: '净收入 (NGR)',
    'Reg Date': '注册日期',
    to: '到',
    All: '全部',
    Summary: '总结',
    link_title: '赚取佣金，从这里开始',
    referral_link: '推荐链接',
    share: '分享',
    pfm_title: '上月表现',
    pfm_title_rebate: '昨日流水',
    pfm_title_daily: '本月表现',
    commission_title: '上一周期佣金',
    apply_agent: '申请成为我们的代理',
    Active: '活跃',
    Turnover: '总流水',
    'Previous Period Negative GGR': '上一周期负毛收入',
    team_details: '团队详情',
    level_details: '等级详情',
    level_details_title: '级别投注详情',
    history: '历史记录',
    list: '列表',
    Number: '编号',
    Status: '状态',
    Lottery: '彩票',
    select_level: '选择级别',
    level1: '一级',
    level2: '二级',
    level3: '三级',
    level4: '四级',
    level1full: '一级代理',
    level2full: '二级代理',
    level3full: '三级代理',
    level4full: '四级代理',
    no_data: '未找到数据',
    banner_text:
      '我们为您提供行业最高的回扣佣金，助力您的事业发展。立即加入太阳城亚洲，享受业内最具竞争力的代理回扣计划，助您登上事业的巅峰。',
    contact: '联系我们',
    kevin_review:
      '之前没有做过代理，只是抱着试试的心情来玩一玩。结果没想到太阳城亚洲的代理计划这么给力，一个月后竟然收到了一大笔佣金！没想到这么容易就能赚钱。以后一定继续努力，争取赚得更多！',
    charlie_review:
      '强力推荐每个玩家都应该当代理，反正也不需要付任何入门费。随便推荐给身边的朋友一起玩就可以赚他们的返佣了。',
    steve_review:
      '试过了几个平台的代理，一开始给的分成都蛮高的但很快就被剥削了。唯独太阳城过了这么久还给超高的代理抽成，真心希望他们越做越大，一起发大财！',
    sandy_review:
      '一开始只是来试玩结果发现蛮容易赢的。那这种福利当然得介绍给我兄弟们。一传十，十传百，就这样意外收了一大堆下线，简直就是躺着赚钱。',
    cindy_review:
      '参加代理计划已经有几个月了。虽然还说不上赚大钱，但当一个副业来说可以破W已经很不错了。',
    qna_title: '问答',
    q1_title: '什么是代理计划？',
    q1_content:
      '代理计划是您与运营商太阳城亚洲之间的合作关系。作为代理营销计划的一部分，运营商会根据您推荐的活跃用户为您支付佣金。',
    q2_title: '成为 太阳城亚洲 代理需要费用吗？',
    q2_content: '不，加入 太阳城亚洲 的代理营销计划不需要任何费用。',
    q3_title: '如何通过计划赚钱？',
    q3_content:
      '当您推荐的玩家通过您的推荐链接注册后，该玩家将与您的代理账户绑定，作为您的下线。每当该玩家下注玩游戏，您将根据系统的公式计算出一部分作为佣金。佣金将于每月5号自动发放。',
    q4_title: '如何跟踪我的表现？',
    q4_content:
      '您可以通过登录太阳城亚洲网站来跟踪您的表现。您可以查看所有相关数据，并进行详细分析。',
    commission1: '如果下线成员产生负利润，代理可以获得 55% 的代理佣金。',
    commission1_text: `<ul>
  <li>活跃成员数量：≥5</li>
  <li>收入/利润佣金：55%</li>
  </ul>
  <div>重要佣金条款和条件：</div>
  <p>
    - 在前三个月内，如果每月有5个活跃成员，您的佣金将为55%。如果累计收入/利润达到300,000人民币，未来三个月的佣金仍保持在55%。只要每个三个月周期的收入/利润达到300,000人民币，您的55%佣金将会继续延长3个月。
  </p>
  <p>
    - 注意：当利润为负或活跃成员未满足要求时，55% 的佣金将被取消，您只能享受基本佣金。
  </p>
  <p>
    - 注意：活跃成员是指月内存款次数≥5，单次存款金额≥1,000，且投注量≥5,000。
  </p>`,
    commission2: '通过增加新的下线成员，代理每人可获得高达 500 人民币的佣金。',
    commission2_text: `如果您有许多热衷于在线赌博的朋友，您可以通过邀请新成员来获得奖励和佣金。佣金的比例与活跃成员的数量挂钩，涵盖四个不同的部分，每个部分的比例都会随着活跃成员的增加而提高。`,
    commission3: '代理可以获得下线成员有效投注额的 1% 佣金。',
    commission3_text: `代理推广要求`,
    commission4: '通过有效推广 太阳城亚洲 ，您将获得工资和奖金。',
    commission4_text: `<p>
  如果您在 Facebook、WhatsApp、Yahoo Messenger、Instagram 等平台上有大量朋友，可以创建群组或社区来推广我们平台的指定广告。
  </p>
  <p>
    我们将为您提供推广费用，该费用仅与您所建立的群组或社区人数及广告发送次数相关。
  </p>
  <p>请发送电子邮件至 cs@sungame.org 进行咨询。</p>`,
    new_members: '新成员',
    active_members: '活跃成员',
    bet_volume: '投注量',
    contact_brand: '联系 太阳城亚洲',
    contact_subtitle: '向我们发送您的问题，我们将在一小时内回复您。',
    turnover_tooltip: '包括至第四级下线成员的流水',
    amount: '金额',
    agent_level: '代理级别',
    total_bet: '总投注',
    recharge: '充值',
    commission_instructions_header: '代理计划与说明',
    commission_instructions1: '太阳城亚洲 代理计划通过吸引新合作伙伴为您提供创收机会。',
    commission_instructions2: '您可以从下线成员的有效投注中赚取最高 1% 的佣金。',
    commission_instructions3: '他的/她的游戏投注金额（无论输赢）均可享受一定比例的佣金。',
    commission_instructions4:
      '为了评估下线成员的有效性，您的仪表盘中提供了按日期和下线成员筛选的详细统计数据。',
    partner_plan_text: '代理计划和说明',
    period_commission: '上期佣金',
    information_card: {
      prev_label: '上一篇',
      next_label: '下一篇',
      login_name: '用户名',
      level: '级别',
      first_deposit_amt: '首次存款金额',
      deposit_amt: '存款金额',
      bet_amt: '投注金额',
      commission: '佣金',
      register_time: '注册时间',
    },
  },
  bonus: {
    available_for: '倒计时',
    available_forever: '无期限',
    days: '天',
    hours: '小时',
    mins: '分钟',
    secs: '秒',
    get_bonus: '领取红利',
    play_now: '进入游戏',
    details: '详情',
    rebate_promo: '获得折扣',
    game_select: '选择游戏继续领取您的红利。',
    spin_now: '立即旋转',
    more_details: '更多详情',
    spin_won_title: '你赢了',
    spin_btn: '旋转',
    promotions: '优惠活动',
    welcome_bonus: '欢迎礼包',
    deposit: '存款',
    vip: 'VIP',
    others: '其他',
    permenant: '永久',
    finished_on: '截止日期：',
    read_more: '查看详情',
    voucher: {
      voucher_title: '兑换码',
      voucher_enter_code: '请输入兑换码',
      successful_redeem: '兑换码兑换成功',
      failed_redeem: '兑换码兑换失败',
    },
    claim: '领取',
    claimed: '已领取',
  },
  vip: {
    vip: 'VIP',
    benefit: '福利',
    info: '当您的投注金额达到要求时，您的VIP等级将在太阳城亚洲平台上实时升级。升级至钻石VIP后，您将享受太阳城亚洲提供的个性化VIP服务，享有更多专属优惠和优待。',
    vip_event: 'VIP专属活动',
    gift: '个性化礼物',
    bonus: '专属奖励',
    privilege_detail: '特权详情',
    privilege_content: `作为玩家，如果您能达到成就的巅峰，您将获得高达167,870的丰厚奖金。此外，您还将享受专属的高级服务和独特的游戏体验，超越常规，尽享奢华待遇。准备好与太阳城亚洲一起踏上这段激动人心的旅程，畅享无限乐趣吧！`,
    rebate_bonus: '实时返水奖励',
    bonus_info: `回扣奖金的计算公式为：(100% - RTP) * 投注金额 * 10%，可在每个游戏的RTP中找到。`,
    daily_bonus: '每日奖励',
    join_vip: '加入我们的VIP俱乐部，领取每日特别奖励，让您的每一天都精彩纷呈。',
    bounce_bonus: '现金回馈奖励',
    bounce_info: `根据您的输赢和投注记录，您将获得相应的奖励。继续参与游戏，第二天即可领取丰厚的现金返还奖励，让您的每次投注都更具回报。`,
    lvlup_bonus: '升级奖励',
    lvlup_info:
      '每次成功升级时，我们都会通过赠送精美礼物来庆祝，助您继续迈向更高的等级，享受更多的奖励和特权。',
    vip_level: 'VIP级别',
    desc1: '达到最高等级的玩家可获得奖金',
    desc2: '，享受优质服务和卓越的玩家体验。',
    privilege1: '回扣奖励',
    privilege2: '每日现金返还',
    privilege3: '每日奖励',
    privilege4: '每周奖励',
    privilege5: '每月奖励',
    privilege6: '生日奖励',
    privilege7: '等级提升奖励',
    privilege8: 'VIP订阅奖励',
    privilege9: '专属客服',
    privilege10: 'VIP旅行',
    privilege11: '全定制体验',
    privilege12: '豪华酒店套房',
    level0: '铁',
    level1: '青铜',
    level2: '白银',
    level3: '黄金',
    level4: '铂金 I',
    level5: '铂金 II',
    level6: '铂金 III',
    level7: '铂金 IV',
    level8: '铂金 V',
    level9: '铂金 VI',
    level10: '钻石',
    play_game: '开始游戏',
    vip_progress: 'VIP进度',
    my_benefit: '我的福利',
    claim: '立即领取',
    'Rakeback Bonus': '实时返水奖励',
    'Bounce Back Bonus': '现金回馈奖励',
    'Daily Bonus': '每日奖励',
    'Weekly Bonus': '每周奖励',
    'Monthly Bonus': '每月奖励',
    'Birthday Bonus': '生日奖励',
    'Level Up Bonus': '等级提升奖励',
    'VIP Subscription Bonus': 'VIP订阅奖励',
    congratulations: '恭喜',
    bonus_reward: '您的奖励将直接转入您的账户，您可以随时查看并使用这些奖励！',
    prize_unlocked: '奖励已解锁',
    vip_bonus_enum: {
      LiveCasino: '真人娱乐场',
      Slots: '老虎机',
      Sports: '体育赛事',
    },
  },
  maintenance: {
    come_back: '我们很快会回来！',
    under_maintain: '我们正在进行维护',
    and: '以及',
    assistant: '如果您需要帮助，请随时联系我们的客服。',
    here: '这里',
    apology:
      '对于给您带来的任何不便，我们深感抱歉。当前正在进行维护，我们会尽快恢复服务，感谢您的耐心等待！',
    reload: '重新加载',
  },
  installation: {
    android: '安卓',
    iOS: 'iOS',
    download: '下载',
    android_title: '安卓设备安装',
    android_content1: '1. 从安卓设备点击“下载”按钮。',
    android_content2:
      '2. 下载完成后，请点击“安装”。如果您的设备提示需要额外确认，请按照提示进行确认。',
    android_content3: '3. 如果您已有账户，请点击“登录”；如果没有账户，请点击“注册”进行创建。',
    ios_title: 'iOS设备安装',
    ios_content1: '1. 在Safari浏览器中打开此页面。',
    ios_content2: '2. 点击屏幕底部菜单中的“分享”图标。',
    ios_content3:
      '3. 选择“添加到主屏幕”并按照提示完成设置。应用将成功安装在您的手机主屏幕上，方便随时使用。',
  },
  subscribe: {
    unsubscribe_successful_title: '成功取消订阅',
    unsubscribe_successful_content1: '您已成功取消电子邮件通讯订阅。',
    unsubscribe_successful_content2: '如果这是误操作，您可以通过点击下面的按钮重新订阅。',
    resubscrible_btn: '重新订阅',
  },
  error: {
    notFound_title: '出了点问题',
    notFound_content: '您要查找的页面已被移动、删除、重命名或可能从未存在。',
    pageError_title: '发生错误',
    pageError_content:
      '别担心，问题不是出在您身上，而是我们这边出了点小问题。对此我们深感抱歉！我们正在积极处理，确保尽快为您解决。',
  },
  funding: {
    imps: '银行卡',
    usdt: 'USDT',
  },
  referral_promotion: {
    ads_title: '免费领取￥{rewardAmount}',
    claim: '领取',
    remaining_withdraw_amount:
      '现在邀请好友，即可参与抽奖！距离提现仅差 {remainingAmount}，快来邀请更多朋友吧！',
    full_withdraw_amount: '恭喜！您可以领取奖励了',
    countdown_expiration: '倒计时至到期：{countdownTime}，抓紧时间！',
    invite_content: '邀请好友来助力',
    lucky_user: '幸运用户',
    registration: '注册记录',
    rule_content1: '1. 累计金额达到{rewardAmount}元时即可开始提现。',
    rule_content2: '2. 邀请好友在太阳城亚洲注册将帮助您快速提现。',
    rule_content3: '3. 每位用户在每次活动中有效期为7天，并可多次参与活动。',
    rule_content4: '4. 如果您获得了额外的现金奖励，您可以免费游戏并有机会赢得更多的真钱奖励。',
    rule_content5: '5. 如果您在7天内没有进行提现，相关资金将会被取消。',
    rule_content6: '6. 为避免文本解释差异，太阳城亚洲保留对本次活动的最终解释权。',
    rule_title: '规则',
    successful_withdraw: '提现成功',
    username_status: '用户名',
    register_time: '注册时间',
    quickWithdrawal: '快速提现',
    congrateText: '恭喜您好运连连！您已赢得',
    rewardText: '已获得免费￥{rewardAmount}奖励',
    claimText: '您已成功领取奖励！',
    spinHitCreditWarningText: '无法抽奖。',
    moreDetailsText: '更多详情',
    inviteStatusInProgress: '您的成员需要完成存款后，才能参与抽奖',
    inviteStatusSuccess: '任务完成',
    spinServerErrorText: '服务器出现错误，请稍后再试',
    inviteBonusInactiveText: '邀请奖励未激活',
    inviteBonusTargetAchievedText: '奖励目标已达成，待领取',
    usedUpAllSpinsText: '用户已用完所有抽奖次数',
  },
  daily_weekly_bonus: {
    tnc: {
      content_daily_1: '系统将在每天0:00重置奖励。',
      content_daily_2: '在同一天或同一周内存款的玩家有资格参与。',
      content_daily_3: '提现前需完成三倍流水。',
      content_daily_4:
        '禁止使用多个账户。若发现通过创建多个账户参与活动或其他违规行为获取的奖励，将予以没收。',
      content_daily_5: '为了避免文本差异，太阳城亚洲保留对此活动的解释权。',
      content_weekly_1: '系统将在周一0:00重置奖励。',
      content_weekly_2: '在同一天或同一周内存款的玩家有资格参与。',
      content_weekly_3: '提现前需完成三倍流水。',
      content_weekly_4:
        '禁止使用多个账户。若发现通过创建多个账户参与活动或其他违规行为获取的奖励，将予以没收。',
      content_weekly_5: '为了避免文本差异，太阳城亚洲保留对此活动的解释权。',
    },
    criteria_type: {
      profit: '利润',
      deposit: '存款',
      betting: '投注',
    },
    bonus_type: {
      daily: '每日',
      weekly: '每周',
    },
    messages: {
      claim_failed: '领取奖励失败！',
      claim_success: '成功领取奖励！',
    },
    todays_profit: '今日利润',
    estimated_bonus: '预计奖金',
    yesterday_bonus: '昨日奖金',
    claimed: '已领取',
    claim: '领取',
    updated_daily_at: '每日01:00更新',
    boost_reward_text: '每次存款都将提升您的奖励！',
    mute_popup: '今天不再显示此弹窗',
    profit_bonus: '利润/奖金',
    daily_profit: '每日利润',
    bonus: '奖金',
    level_desc_deposit: '存款越多，奖励越多！现在行动，享受更多惊喜！',
    level_desc_betting: '让您的投注成为财富的钥匙，继续投注，解锁更多奖励！',
  },
  daily_bonus: {
    still_cant_claim_free_spin: '仍然无法领取您的免费抽奖？',
    login_claim_free_spin: '请立即登录领取您的免费抽奖',
    deposit_amt: '存款金额',
    valid_bet_amt: '有效投注金额',
    freespin_available: '可用免费抽奖',
    claim: '领取',
    dont_show_today: '今天不再显示',
    today: '今天',
    day_1: '第',
    day_2: '天',
    details: '详情',
    banner_title: '点击按钮，开启您的财富之旅！',
  },
  deposit_app: {
    reward_loyalty: '亲爱的用户，作为您忠诚的回报，我们特此赠送您',
    claim_reward: '请在APP中领取您的奖励！',
    claim: '领取',
    login_acc: '请登录您的账户。谢谢！',
    binding_acc: '哦，看来您还没有完成绑定',
    email: '电子邮件',
    mobile: '手机',
    completed_bonus: '您已完成所有奖励。',
    details: '详情',
    deposit_more_than: '存款超过',
    claim_bonus_today: '领取今天的奖励！',
    day: '天',
    bonus: '奖励',
    day_1: '第',
    day_2: '天',
  },
  vault: {
    vault_title: '保险库访问',
    vault_message: '提现前您需要完成手机和邮箱验证。',
    vault_deposit: '保险库存款',
    vault_withdrawal: '保险库取款',
    currentBalance_text: '当前余额',
    vaultBalance_text: '保险库余额',
    deposit: '存款',
    withdraw: '取款',
    vault_balance: '保险库余额',
    depositAmt_text: '存款金额',
    withdrawAmt_text: '取款金额',
    login_text: '请输入登录密码',
    success_text: '已成功转移',
    password_empty: '请填写您的密码',
  },
  common: {
    cancelText: '取消',
    submitText: '提交',
    verifyText: '核实',
  },
  provider: {
    lottery: '彩票',
    popular: '热门',
    provider: '游戏商',
  },
  game: {
    recents: '最近',
    favourite: '收藏',
  },
  daily_detail: {
    register: '注册',
    deposit_amt: '存款金额/数量',
    first_deposit_amt: '首次存款金额/数量',
    bet_amt: '投注金额/号码',
    commission: '佣金',
    search_time: '搜索时间',
  },
};

export default zh;
