import React, { forwardRef, useEffect, useState } from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';
import { IoClose } from '@react-icons/all-files/io5/IoClose';
import IconCloseCustomSnackbar from 'component/mobile/common/icon/IconCloseCustomSnackbar';
import styles from './CustomSnackBar.module.css';
import { MobileImages } from 'common/constants/image-resource';

const CustomSnackBar = forwardRef((props, ref) => {
  const {
    id,
    message,
    title,
    variant,
    autoHideDuration, //extract these props below to prevent DOM warning
    iconVariant,
    hideIconVariant,
    anchorOrigin,
    persist,
    ...other
  } = props;
  const { closeSnackbar } = useSnackbar();
  const [statusImg, setStatusImg] = useState(null);

  useEffect(() => {
    let statusImg,
      bgColor = '';
    if (variant) {
      switch (variant) {
        case 'success':
          statusImg = MobileImages.notiSuccess;
          bgColor = 'radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #2DDE14 0%, #149F00 100%)';
          break;
        case 'error': // default if no variant also error
          statusImg = MobileImages.notiError;
          bgColor = 'radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #FC0B36 0%, #C90A2C 100%)';
          break;
        // case 'payment':
        //   statusImg = MobileImages.notiPayment;
        //   bgColor = 'radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #FCBC00 0%, #E3A900 100%)';
        //   break;
        case 'warning':
          statusImg = MobileImages.notiInfo;
          bgColor = 'radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #C83434 0%, #962020 100%)';
          break;
        case 'info':
        case 'default':
        default:
          statusImg = MobileImages.notiWarning;
          bgColor = 'radial-gradient(85.83% 1074.42% at 2.18% 13.24%, #FF6F00 0%, #D95E00 100%)';
          break;
      }
      setStatusImg({
        statusIcon: statusImg,
        bgColor: bgColor,
      });
    }
  }, [variant]);

  const handleCloseSnackbar = () => closeSnackbar(id);

  // using example:
  // import { useSnackbar } from 'notistack';
  // const { enqueueSnackbar } = useSnackbar();
  //   enqueueSnackbar('this is message', {
  //     title: 'A Test',
  //     variant: 'info', //(success, error, warning, info), default is error,if it is error, can ignore this parameter
  //   });

  return (
    <SnackbarContent ref={ref} role="alert" {...other}>
      {statusImg !== null && (
        <div className={styles.container} style={{ background: `${statusImg.bgColor}` }}>
          <div className={styles.icons}>
            <img src={statusImg.statusIcon} alt="status" width={42} height={42} />
          </div>
          <div className={styles.info}>
            {title !== null && <div className={styles.infoTitle}>{title}</div>}
            <div className={styles.infoContent}>{message}</div>
          </div>
          <div onClick={handleCloseSnackbar} className={styles.close}>
            <IconCloseCustomSnackbar fontSize={30} />
          </div>
        </div>
      )}
    </SnackbarContent>
  );
});

export default CustomSnackBar;
